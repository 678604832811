import React from "react";
import { useNavigate } from "react-router";
import { REPLACE_AIR } from "../../replace_str_constant";
import { appName } from "../../config";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { openGame } from "../../redux/action/game/gameAction";

const GAMEITEM = ({ index, hasBottomTitle, gameProps }) => {
  const { platform, gameCode, gameType, en_Name, zh_Name } = gameProps;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  return (
    <div
      key={index}
      className={`rounded-[15px] lg:h-[220px] h-[160px] mb-[10px] min-w-[110px] group bg-main-color relative cursor-pointer overflow-hidden shadow-xl`}
      onClick={() => {
        navigate(
          `?platform=${platform}&code=${gameCode}&type=${gameType}&ename=${REPLACE_AIR(
            en_Name
          )}&cname=${zh_Name}`
        );
        dispatch(openGame());
      }}
    >
      <div
        style={{
          backgroundImage: `url(https://911winprotest.911win.co/images-webp/gameIcon/en/${platform}/${REPLACE_AIR(
            en_Name
          )}.webp)`,
          backgroundSize: "contain",
        }}
        className={`w-full h-full absolute scale-[2] opacity-[.4] rounded-[15px] bg-center bg-no-repeat ${
          hasBottomTitle && "!h-[130px] rounded-b-[0px]"
        }`}
      />
      <div
        style={{
          backgroundImage: `url(https://911winprotest.911win.co/images-webp/gameIcon/en/${platform}/${REPLACE_AIR(
            en_Name
          )}.webp)`,
          backgroundSize: "cover",
        }}
        className={`w-full h-full absolute rounded-[15px] bg-center bg-no-repeat ${
          hasBottomTitle && "!h-[130px] rounded-b-[0px]"
        }`}
      />
      <div
        className={`bg-game-bottomText absolute overflow-hidden bottom-0 left-0 text-white flex flex-col items-center justify-center h-[30px] w-full ${
          hasBottomTitle && "rounded-b-[15px] pb-[0px] "
        }`}
      >
        <p className="text-lg text-shadow font-bold mb-0 text-overflow w-full text-center">
          {i18next.language === "en" ? en_Name : zh_Name}
        </p>
        {/* {hasBottomTitle || (
          <p className="font-bold mb-0 px-[5px] bg-[rgb(255,255,255,.4)] rounded-full text-overflow w-[90%]">
            {appName} Special
          </p>
        )} */}
      </div>
    </div>
  );
};

export default GAMEITEM;
