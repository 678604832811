import { useSelector } from "react-redux";
import { formatNumber } from "./formatNumber";
import { useMemo } from "react";

const MemberBalance = () => {
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const balance = useMemo(() => {
    return formatNumber(isMemberInfo?.money);
  }, [isMemberInfo?.basedisplaycur, isMemberInfo?.money]);

  return balance;
};
export default MemberBalance;
