import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { Button } from "semantic-ui-react";

const SignInUp = ({ Static }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  return (
    <section className="flex gap-[5px]">
      <Button
        content={i18n("signin")}
        onClick={() => {
          navigate("/signin");
        }}
        className={`${
          Static
            ? "!bg-white !text-[#323c3c]"
            : location.pathname === "/signin"
            ? "!bg-main-color !text-second-color"
            : "!bg-third-color !text-white"
        }`}
      />
      <Button
        content={i18n("signup")}
        onClick={() => {
          navigate("/signup");
        }}
        className={`${
          Static
            ? "!bg-main-color !text-second-color"
            : location.pathname === "/signup"
            ? "!bg-main-color !text-second-color"
            : "!bg-third-color !text-white"
        }`}
      />
    </section>
  );
};

export default SignInUp;
