import { useEffect, useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

import InputComponents from "../../components/InputComponents/InputComponents";
import { loginUser, sendSmsCode, signupUser } from "../../api/api";
import { useDispatch } from "react-redux";
import { loading, loadingDestroy, notice } from "../../redux/action/action";
import { useNavigate } from "react-router";
import { cryptData } from "../../utils/encryptData";
import GetParams from "../../utils/getParams";
import { useTranslation } from "react-i18next";

const PhoneSignup = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const [watchPassword, setWatchPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [countdown, setCountdown] = useState(0); // 60
  const [data, setData] = useState({});
  const { userName, password, mobile, smsCode } = data;
  const [dataFocus, setDataFocus] = useState({});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const inviteCode = GetParams("invitecode");

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleGetSmsCode = () => {
    sendSmsCode({
      mobile: mobile,
    }).then((data) => {
      if (data.code === "Ok") {
        dispatch(notice(data.msg, "success"));
        setCountdown(60);
      } else {
        dispatch(notice(data.msg, "error"));
      }
    });
  };

  const handleSignup = () => {
    dispatch(loading());
    signupUser({
      userName: userName,
      password: cryptData(password),
      mobile: mobile,
      smscode: smsCode,
      invited: inviteCode,
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(loadingDestroy());
          dispatch(notice(data.msg, "Sign up success"));
          setButtonLoading(false);

          //註冊後登入
          loginUser({
            loginName: userName,
            password: cryptData(password),
          }).then((data) => {
            if (data.code === "Ok") {
              if (window.sessionStorage.getItem("sess_911betnet")) {
                window.sessionStorage.removeItem("sess_911betnet");
              }
              window.sessionStorage.setItem("sess_911betnet", data.oauth);
            }
            navigate("/");
          });
        } else {
          dispatch(notice(data.msg, "error"));
          setButtonLoading(false);
        }
      })
      .finally(() => {
        dispatch(loadingDestroy());
      });
  };

  const inputList = [
    {
      label: "user name",
      name: "userName",
      type: "text",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "password",
      name: "password",
      type: watchPassword ? "text" : "password",
      needIcon: true,
      icon1: watchPassword ? "eye slash" : "eye",
      function: () => setWatchPassword(!watchPassword),
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "phone number",
      name: "mobile",
      type: "number",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        console.log(data);
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "sms code",
      name: "smsCode",
      type: "text",
      needButton: true,
      buttonProps: {
        disabled: countdown > 0,
        onClick: () => handleGetSmsCode(),
      },
      buttonText:
        countdown > 0 ? `Resend after ${countdown} seconds` : "Send sms",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
  ];

  return (
    <section>
      {inputList.map((input, index) => {
        return (
          <InputComponents
            focusProps={dataFocus}
            dataProps={data}
            setDataProps={setData}
            inputProps={input}
            index={index}
            onFocus={(e) => setDataFocus({ [e.target.name]: true })}
          />
        );
      })}
      <Button
        onClick={handleSignup}
        className="w-full !bg-main-color !mt-[30px]"
      >
        {i18n("signup")}
      </Button>
    </section>
  );
};

export default PhoneSignup;
