import { useEffect, useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

import { animatePageNotOpacity } from "../../animateConstant";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import InboxItem from "./InboxItem";
import { useDispatch, useSelector } from "react-redux";
import { getFixedStyle } from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { getInboxList } from "../../api/api";
import Empty from "../../components/Empty/Empty";

const Inbox = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`inbox.${key}`);

  const [inboxList, setInboxList] = useState([]);

  const detailState = useSelector((state) => state.isDetailPage);

  useEffect(() => {
    getInboxList()
      .then((data) => {
        if (data.code === "Ok") {
          setInboxList(data.data);
        } else {
        }
      })
      .finally(() => {});
  }, []);
  return (
    <AnimateSharedLayout>
      <main className={` ${getFixedStyle()} bg-second-color`}>
        <CloseDetailPage title={i18n("title")} />
        <motion.section
          layout
          initial={!detailState ? "hiddenRight" : "hiddenLeft"}
          animate="visible"
          exit={!detailState ? "hiddenRight" : "hiddenLeft"}
          variants={animatePageNotOpacity}
          className="p-common-padding-x"
        >
          {inboxList.length === 0 ? (
            <Empty />
          ) : (
            inboxList.map((item, index) => {
              return <InboxItem key={index} data={item} />;
            })
          )}
        </motion.section>
      </main>
    </AnimateSharedLayout>
  );
};

export default Inbox;
