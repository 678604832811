import { useNavigate } from "react-router";

import { RiUserFill } from "react-icons/ri";
import SlideMenu from "./SlideMenu";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FILTER_CURRENCY_IMAGE } from "../../currency";
import { IoWallet } from "react-icons/io5";

const SignInData = () => {
  const [openSlideMenu, setOpenSlideMenu] = useState(false);

  const memberData = useSelector((state) => state.isMemberInfo);

  const slideMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (slideMenuRef.current && !slideMenuRef.current.contains(event.target)) {
      setOpenSlideMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <section className="flex items-center gap-[5px]">
      <IoWallet
        onClick={() => navigate("deposit")}
        className="text-3xl text-light-text cursor-pointer"
      />
      <div className="relative">
        <RiUserFill
          onClick={() => setOpenSlideMenu(!openSlideMenu)}
          onMouseEnter={() => setOpenSlideMenu(!openSlideMenu)}
          className="text-3xl text-light-text cursor-pointer"
        />
        {openSlideMenu && <SlideMenu ref={slideMenuRef} />}
      </div>
    </section>
  );
};

export default SignInData;
