import { Fragment, useMemo, useState } from "react";
import { motion } from "framer-motion";

import ShowGameDetail from "../ShowGameDetail";
import { useDispatch, useSelector } from "react-redux";
import { REPLACE_AIR } from "../../replace_str_constant";
import { useNavigate } from "react-router";
import { openGame } from "../../redux/action/game/gameAction";

const SearchBox = ({ searchValue }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const GAMELIST = useSelector((state) => state.isGameList);

  const CQ9 = GAMELIST?.["CQ9"]?.["SLOT"];

  const flatGameList = useMemo(() => {
    return Object.values(GAMELIST)
      .flatMap((values) => Object.values(values))
      .flat();
  }, [GAMELIST]);

  const GAME_LIST_FILTER = useMemo(() => {
    if (searchValue.length < 3) return [];
    return flatGameList.filter((game) => {
      const GAME = REPLACE_AIR(game?.ename);
      return GAME?.toLowerCase()?.includes(searchValue.toLowerCase());
    });
  }, [searchValue]);

  return (
    <Fragment>
      <motion.section
        initial={{ transform: "scaleX(0)" }}
        animate={{ transform: "scaleX(1)" }}
        transition={{ duration: 0.2, ease: "" }}
        style={{ transformOrigin: "top right" }}
        className="mt-[10px] rounded-[6px] border border-light-border bg-third-color px-common-padding-x py-common-padding-y w-full relative z-[2000] overflow-hidden"
      >
        <p className="text-white text-sm">Recommend</p>
        <section className="w-full max-h-[30vh] overflow-y-scroll">
          {GAME_LIST_FILTER.map((game, index) => {
            const en_Name = game?.ename;
            const zh_Name = game?.cname;
            const platform = game?.platform;
            const gameCode = game?.gameCode;
            const gameType = game?.gameType;

            return (
              <section
                key={en_Name}
                className="flex items-center cursor-pointer"
              >
                <div
                  className="min-w-[36px] h-[36px] bg-center bg-cover rounded-[6px] mr-[10px]"
                  style={{
                    backgroundImage: `url(./images-webp/gameIcon/en/${platform}/${REPLACE_AIR(
                      en_Name
                    )}.webp)`,
                    backgroundSize: "contain",
                  }}
                ></div>
                <div
                  className="flex flex-col justify-center w-full"
                  onClick={() => {
                    navigate(
                      `?platform=${platform}&code=${gameCode}&type=${gameType}&ename=${REPLACE_AIR(
                        en_Name
                      )}&cname=${zh_Name}`
                    );
                    dispatch(openGame());
                  }}
                >
                  <p className="mb-0 text-white font-semibold">{en_Name}</p>
                  <p className="mb-0 text-light-text text-sm font-semibold">
                    {gameType}
                  </p>
                </div>
              </section>
            );
          })}
        </section>
      </motion.section>
    </Fragment>
  );
};

export default SearchBox;
