import { CURRENCY_SYMBOL } from "../../../constant";

const ReferColumn = ({ border, title, value }) => {
  return (
    <section>
      <p className="mb-[10px] font-semibold text-dark-gray-text sm:text-xl">
        {title}
      </p>
      <p className="mb-[10px] font-extrabold text-2xl text-dark-gray-text sm:text-l">
        {CURRENCY_SYMBOL} {value}
      </p>
      {border && <div className="w-full h-[.1px] bg-white mb-[10px]"></div>}
    </section>
  );
};

export default ReferColumn;
