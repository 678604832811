import { AnimateSharedLayout, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { IoCloseOutline } from "react-icons/io5";

import SelectMethod from "./components/SelectMethod";

import { animatePage } from "../../animateConstant";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import LoaderComponent from "../../components/Loading/LoaderComponent";

const DepositStepOne = ({
  setChooseCurrency, //選擇幣別
  setDepositStep,
  depositStep, //目前步驟
  depositMethod, //提款方式
  isLoading,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);

  return (
    <AnimateSharedLayout>
      <motion.section
        layout
        initial={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <div
          className={`flex items-center text-light-text text-4xl mb-[20px] justify-end`}
        >
          <CloseComponent />
        </div>
        <p className="text-3xl text-main-color font-extrabold text-center">
          {i18n("deposit")}
        </p>

        <section>
          <p className="text-center text-light-text my-[20px]">
            {i18n("methodText")}
          </p>
          <section className="mb-[24px] p-2">
            <p className="font-extrabold text-xl text-main-color mb-[10px]">
              {i18n("recommend")}
            </p>
            {depositMethod.map((item, index) => {
              const method = item.payway3; //提款方式
              const min = item.min; //最小金額
              const max = item.max; //最大金額

              return (
                <LoaderComponent commonLoading={isLoading}>
                  <SelectMethod
                    setChooseCurrency={setChooseCurrency}
                    setDepositStep={setDepositStep}
                    currency={`PHP`}
                    method={method}
                    min={min}
                    max={max}
                  />
                </LoaderComponent>
              );
            })}
          </section>
          {/* <section className="mb-[24px]">
            <p className="font-extrabold text-xl text-main-color mb-[10px]">
              {i18n("other")}
            </p>
            <SelectMethod
              setChooseCurrency={setChooseCurrency}
              setDepositStep={setDepositStep}
              currency={`USDT`}
              method={`Deposit`}
            />
          </section> */}
        </section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default DepositStepOne;
