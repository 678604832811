import mainLogo from "../images/Logo/main-logo.webp";
import mainLogoWhite from "../images/Logo/main-logo-white.webp";
import carousel1 from "../images/Carousel/carousel1.jpeg";
import carousel2 from "../images/Carousel/carousel2.jpeg";

export const stylesConfig = {
  mainLogo: mainLogo,
  mainLogoWhite: mainLogoWhite,
  carouselImages: [carousel1, carousel2],
};
