import { Button } from "semantic-ui-react";
import InputComponents from "../../components/InputComponents/InputComponents";
import { useEffect, useState } from "react";
import { forgetPasswordOTP, forgetPasswordSendSms } from "../../api/api";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import InputCopy from "../../components/CopyComponent/InputCopy";
import { useNavigate } from "react-router";

const PhoneForget = ({ setStep }) => {
  const [data, setData] = useState({});
  const [comfirmCopy, setComfirmCopy] = useState(false);
  const [dataFocus, setDataFocus] = useState({});
  const [countdown, setCountdown] = useState(0); // 60
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleGetSmsCode = () => {
    setButtonLoading(true);
    forgetPasswordSendSms({
      username: data?.mobile,
      mobile: data?.mobile,
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(notice(data.msg, "success"));
          setCountdown(60);
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const handleGetOTP = () => {
    setButtonLoading(true);
    forgetPasswordOTP({
      username: data?.mobile,
      mobile: data?.mobile,
      smsCode: data?.smsCode,
    })
      .then((obj) => {
        if (obj.code === "Ok") {
          dispatch(notice(obj.msg, "success"));
          setData({ ...data, OTP: obj.OTP });
        } else {
          dispatch(notice(obj.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const handleNextStep = () => {
    navigate(`?mobile=${data?.mobile}`);
    setStep(2);
  };

  const inputList = [
    {
      label: "phone number",
      name: "mobile",
      type: "number",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "sms code",
      name: "smsCode",
      type: "text",
      needButton: true,
      buttonProps: {
        disabled: countdown > 0,
        onClick: () => handleGetSmsCode(),
      },
      buttonText:
        countdown > 0 ? `Resend after ${countdown} seconds` : "Send sms",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
  ];

  return (
    <section>
      {inputList.map((input, index) => {
        return (
          <InputComponents
            focusProps={dataFocus}
            dataProps={data}
            setDataProps={setData}
            inputProps={input}
            index={index}
          />
        );
      })}
      {data?.OTP && (
        <InputCopy setComfirmCopy={setComfirmCopy} copyLink={`${data?.OTP}`} />
      )}
      {data?.OTP ? (
        <Button
          loading={buttonLoading}
          disabled={buttonLoading || !comfirmCopy}
          onClick={() => handleNextStep()}
          className="w-full !bg-main-color !mt-[30px]"
        >
          {comfirmCopy ? "Next" : "Please copy OTP"}
        </Button>
      ) : (
        <Button
          loading={buttonLoading}
          disabled={buttonLoading}
          onClick={() => handleGetOTP()}
          className="w-full !bg-main-color !mt-[30px]"
        >
          Submit
        </Button>
      )}
    </section>
  );
};

export default PhoneForget;
