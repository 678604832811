import { notice } from "../redux/action/action";
import {
  cleanMemberInfo,
  StoreCryptoRatio,
  storeMemberInfo,
} from "../redux/action/member/memberAction";
import UserLogout from "./UserLogout";
import { getCryptoRatio, getMemberInfo } from "./api";

export const updateDataPeriodically = (dispatch) => async () => {
  try {
    const [cryptoRatioData] = await Promise.all([getCryptoRatio()]);

    if (cryptoRatioData) {
      dispatch(StoreCryptoRatio(cryptoRatioData));
    }

    //以上無需驗證oauth

    if (window.sessionStorage.getItem("sess_911betnet") !== null) {
      const [memberInfoData] = await Promise.all([getMemberInfo()]);

      if (memberInfoData.code === "Ok") {
        dispatch(storeMemberInfo(memberInfoData));
      } else {
        dispatch(
          notice(
            "Your account has been logged in to another location",
            "error",
            15
          )
        );
        dispatch(cleanMemberInfo());
        setTimeout(() => {
          UserLogout();
          window.location.reload();
        }, 1500);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
