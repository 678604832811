export const storeGameList = (data) => {
  return {
    type: "storeGameList",
    payload: data,
  };
};
export const storeGameLink = (link) => {
  return {
    type: "storeGameLink",
    payload: link,
  };
};
export const openGame = () => {
  return {
    type: "openGame",
  };
};
export const closeGame = () => {
  return {
    type: "closeGame",
  };
};
