import { useMemo } from "react";
import { useSelector } from "react-redux";
import FilterGameList from "../../utils/FilterGameType";
import { logoMapping } from "../../utils/LogoMapping";
import { useNavigate } from "react-router";
import GetParams from "../../utils/getParams";

const PlatformSelect = ({ defaultPlatform, platformType }) => {
  const games = FilterGameList({ TYPE: platformType });

  const params = GetParams("platform");

  const platform = useMemo(() => {
    return params ? params : defaultPlatform;
  }, [params]);

  const navigate = useNavigate();

  return (
    <section className="grid grid-cols-4 overflow-x-scroll gap-2 h-[70px] px-common-padding-x platform-scroll no-scrollbar border-y bg-platform-bg border-[#ffdfa5]">
      {games?.map((game, index) => {
        return (
          <div
            key={index}
            className={`flex items-center flex-col justify-between py-[10px] cursor-pointer hover:bg-[#ffdfa5] ${
              platform === game && `bg-[#ffdfa5]`
            }`}
            onClick={() => navigate(`?platform=${game}`)}
          >
            <img
              className="w-[50px] object-contain h-[25px]"
              src={logoMapping(game)}
            />
            <p className="mb-0 font-bold text-white text-shadow">{game}</p>
          </div>
        );
      })}
    </section>
  );
};

export default PlatformSelect;
