import React from "react";
import { CURRENCY_SYMBOL } from "../../../constant";
import { filterNumberColor, filterSign } from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";

const Record = ({ gameName, jackpotWin, bet, winLose }) => {
  return (
    <div className="border-b border-input-border-color py-common-padding-y px-common-padding-x">
      <p className="text-main-color text-lg font-bold mb-[7px] flex items-center">
        {gameName}{" "}
        <img
          className="w-[30px] h-[30px] object-contain ml-[5px]"
          src={`https://demo12345.e78slot.com/images-webp/logo/${gameName}.png`}
        />
      </p>
      <div className="flex justify-between">
        <div className="flex flex-col text-light-text">
          <p className="text-sm mb-[5px]">Jackpot Win</p>
          <p className="text-lg text-dark-gray-text">
            {CURRENCY_SYMBOL} {jackpotWin}
          </p>
        </div>
        <div className="flex flex-col text-light-text">
          <p className="text-sm mb-[5px]">Bet</p>
          <p className="text-lg text-dark-gray-text">{bet}</p>
        </div>

        <div className="flex flex-col text-light-text">
          <p className="text-sm mb-[5px]">Win/Lose</p>
          <p className={`text-lg ${filterNumberColor(winLose)}`}>
            {filterSign(winLose)} {CURRENCY_SYMBOL} {winLose}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Record;
