import { FaInbox } from "react-icons/fa";

const Empty = () => {
  return (
    <section className="flex flex-col items-center justify-center py-[20px]">
      <FaInbox className="text-5xl text-light-white" />
      <p className="font-semibold text-light-white">No data...</p>
    </section>
  );
};

export default Empty;
