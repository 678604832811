import axios from "axios";
import { API_URL, commonHeader } from "./apiConfig";
import { getMobileOperatingSystem } from "../utils/DetectMobileType";
import InApp from "detect-inapp";

//some setting

const device = getMobileOperatingSystem();
const inapp = new InApp(
  navigator.userAgent || navigator.vendor || window.opera
);
const ISAPP = inapp.isInApp
  ? "APP"
  : window.matchMedia("(display-mode: standalone)").matches
  ? "PWA"
  : "WEB";
const DEVICE = `${device} (${ISAPP})`; //設備檢測
// useEffect(() => {
//   effect
//   return () => {
//     cleanup
//   };
// }, []);

//base axios
const api = axios.create({
  baseURL: API_URL,
  headers: commonHeader,
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // 在这里处理错误
    console.error(error);
    return Promise.reject(error);
  }
);

//API類別-> action(動作) get(獲得) post(新增) put(修改) delete(刪除)

//action
export function loginUser({ loginName, password, country } = {}) {
  return api.post("/", {
    method: "get_access_token",
    memId: loginName,
    passwd: password,
    country: country,
    device: DEVICE,
  });
}

export function logoutUser() {
  return api.post("/", {
    method: "logout_access_token",
  });
}

export function getMemberInfo() {
  return api.post("/", {
    method: "get_account_balance",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function signupUser({
  userName,
  mobile,
  password,
  smscode,
  invited,
  country,
} = {}) {
  return api.post("/", {
    method: "createMember",
    memId: userName,
    passwd: password,
    mobile: mobile,
    smscode: smscode,
    country: country,
    invited: invited,
    device: DEVICE,
  });
}

export function sendSmsCode({ mobile, country } = {}) {
  return api.post("/", {
    method: "sendsms",
    mobile: mobile,
    country: country,
  });
}

export function getGameList({} = {}) {
  return api.post("/", {
    method: "getgamelist",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function checkOauth() {
  return api.post("/", {
    method: "check_access_token",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function enterGame({ platform, gameType, gameCode, isPc } = {}) {
  return api.post("/", {
    method: "forward_game",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    platform: platform,
    gameType: gameType,
    gameCode: gameCode,
  });
}

export function depositMethodList() {
  return api.post("/", {
    method: "getPayList",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function withdrawMethodList() {
  return api.post("/", {
    method: "getCollectList",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function depositFund({ payway3, amount } = {}) {
  return api.post("/", {
    method: "deposit",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    payway3: payway3,
    amount: amount,
  });
}

export function updateWalletType({ wallettype } = {}) {
  return api.post("/", {
    method: "update_account_wallettype",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    wallettype: wallettype,
  });
}

export function setNowCurrency({ basedisplaycurr } = {}) {
  return api.post("/", {
    method: "update_account_basedisplaycur",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    basedisplaycur: basedisplaycurr,
  });
}

export function getCryptoRatio() {
  return api.post("/", {
    method: "getCryptoRatio",
  });
}

export function withdrawFund({
  real_name,
  amounts,
  currency,
  cryptoChain,
  type,
  bank_payWay,
  bank_branch,
  bank_account,
} = {}) {
  return api.post("/", {
    method: "withdraw",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    amount: amounts,
    real_name: real_name,
    Currency: currency,
    cryptoChain: cryptoChain,
    type: type,
    bank_payWay: bank_payWay,
    bank_branch: bank_branch,
    bank_account: bank_account,
  });
}

export function withdrawCollect({ payway3, amounts } = {}) {
  return api.post("/", {
    method: "collect",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    payway3: payway3, //代付欄位
    amount: amounts,
  });
}

export function getGameReport({ etd, std, platform } = {}) {
  return api.post("/", {
    method: "report",
    etd: etd,
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    platform: "all",
    std: std,
    timeinterval: "custom",
  });
}

export function getCashReport({ etd, std } = {}) {
  return api.post("/", {
    method: "cashtran",
    etd: etd,
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    std: std,
    timeinterval: "custom",
  });
}

export function getInboxList() {
  return api.post("/", {
    method: "messagelist",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
  });
}

export function getPromotionList() {
  return api.post("/", {
    method: "getPromotion",
  });
}

export function updateBankAccount(bankData) {
  return api.post("/", {
    method: "updateBankInfo",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    ...bankData,
  });
}

export function manualWithdraw({
  amounts,
  real_name,
  type,
  bank_payWay,
  bank_branch,
  bank_account,
} = {}) {
  return api.post("/", {
    method: "withdraw",
    oauth: window.sessionStorage.getItem("sess_911betnet"),
    amount: amounts,
    real_name: real_name,
    Currency: "PHP",
    cryptoChain: "",
    type: type,
    bank_payWay: bank_payWay,
    bank_branch: bank_branch,
    bank_account: bank_account,
  });
}

export function forgetPasswordSendSms({ username, mobile, country } = {}) {
  return api.post("/", {
    method: "sendsmsforgetpasswd",
    username: username,
    mobile: mobile,
    country: country,
    device: DEVICE,
  });
}

export function forgetPasswordOTP({ username, mobile, smsCode, country } = {}) {
  return api.post("/", {
    method: "checkforgetpasswd",
    username: username,
    mobile: mobile,
    smscode: smsCode,
    country: country,
    device: DEVICE,
  });
}

export function resetPassword({
  username,
  mobile,
  newpassword,
  OTP,
  country,
} = {}) {
  return api.post("/", {
    method: "resetpasswd",
    username: username,
    mobile: mobile,
    newpassword: newpassword,
    OTP: OTP,
    country: country,
    device: DEVICE,
  });
}
