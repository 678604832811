import { combineReducers } from "redux";
import LoadingReducers from "./LoadingReducers";
import NoticeReducers from "./NoticeReducers";
import DetailPageReducers from "./DetailPage";
import MenuReducers from "./OpenMenu";
import MenuSelectReducers from "./MenuStatus";
import MemberInfoReducers from "./member/memberInfo";
import GameListReducers from "./game/gameList";
import GameLinkReducers from "./game/gameLink";
import LoadingStatusReducers from "./loading/LoadingStatus";
import DepositLinkReducers from "./cashflow/depositLink";
import OpenGameReducers from "./game/openGame";
import cryptoReducers from "./member/cryptoRate";

const AllReducers = combineReducers({
  isLoading: LoadingReducers,
  isNotice: NoticeReducers,
  isDetailPage: DetailPageReducers,
  isMenuOpen: MenuReducers,
  isMenuSelect: MenuSelectReducers,
  isMemberInfo: MemberInfoReducers,
  isGameList: GameListReducers,
  isGameLink: GameLinkReducers,
  isLoaderStatus: LoadingStatusReducers,
  isDepositLink: DepositLinkReducers,
  isGameOpen: OpenGameReducers,
  isCryptoRatio: cryptoReducers,
});

export default AllReducers;
