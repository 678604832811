import React, { useState } from "react";
import InputComponents from "../../components/InputComponents/InputComponents";
import { Button } from "semantic-ui-react";
import { resetPassword } from "../../api/api";
import GetParams from "../../utils/getParams";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import { useNavigate } from "react-router";

const ChangePassword = () => {
  const [data, setData] = useState({});
  const [watchPassword, setWatchPassword] = useState(false);
  const [watchComfirmPassword, setWatchComfirmPassword] = useState(false);
  const [dataFocus, setDataFocus] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  const mobile = GetParams("mobile");

  const dispatch = useDispatch();

  const handleChangePassword = () => {
    if (data.password !== data.comfirmPassword) {
      return dispatch(notice("Password not match", "error"));
    }
    setButtonLoading(true);
    resetPassword({
      username: mobile,
      mobile: mobile,
      newpassword: data.password,
      OTP: data.OTP,
    })
      .then((obj) => {
        if (obj.code === "Ok") {
          dispatch(notice(obj.msg, "success"));
          navigate("/signin");
        } else {
          dispatch(notice(obj.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const inputList = [
    {
      label: "New password",
      name: "password",
      type: watchPassword ? "text" : "password",
      needIcon: true,
      icon1: watchPassword ? "eye slash" : "eye",
      function: () => setWatchPassword(!watchPassword),
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "Comfirm password",
      name: "comfirmPassword",
      type: watchComfirmPassword ? "text" : "password",
      needIcon: true,
      icon1: watchComfirmPassword ? "eye slash" : "eye",
      function: () => setWatchComfirmPassword(!watchComfirmPassword),
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
    {
      label: "OTP",
      name: "OTP",
      type: "text",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
      onFocus: (e) => setDataFocus({ [e.target.name]: true }),
    },
  ];

  return (
    <section>
      {inputList.map((input, index) => {
        return (
          <InputComponents
            focusProps={dataFocus}
            dataProps={data}
            setDataProps={setData}
            inputProps={input}
            index={index}
          />
        );
      })}

      <Button
        onClick={() => handleChangePassword()}
        className="w-full !bg-main-color !mt-[30px]"
      >
        Submit
      </Button>
    </section>
  );
};

export default ChangePassword;
