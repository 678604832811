import { motion, AnimatePresence } from "framer-motion";

import { popUpFromTopVariant } from "../../animateConstant";

const ErrorHint = ({ notMargin, children }) => {
  return (
    <AnimatePresence>
      <motion.p
        initial={popUpFromTopVariant.initial}
        animate={popUpFromTopVariant.animate}
        exit={popUpFromTopVariant.exit}
        transition={popUpFromTopVariant.transition}
        className={`text-red-500 ${notMargin && "mb-0"}`}
      >
        {children}
      </motion.p>
    </AnimatePresence>
  );
};

export default ErrorHint;
