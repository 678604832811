const CommonBorder = ({ notMargin, dotted }) => {
  return (
    <div
      className={`border-b border-input-border-color ${
        notMargin || "my-[15px]"
      } ${dotted && "border-dotted !border-b-2"}`}
    ></div>
  );
};

export default CommonBorder;
