import { RiVipCrownFill } from "react-icons/ri";
import { AiTwotoneSound, AiFillDollarCircle } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { TbPokerChip, TbMessage } from "react-icons/tb";
import { BsCashCoin, BsListCheck } from "react-icons/bs";
import { IoGift, IoSettings } from "react-icons/io5";
import { FaRegCalendarCheck } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { CgUserList } from "react-icons/cg";
import { FaPiggyBank, FaHistory } from "react-icons/fa";
import { RiHomeSmileFill } from "react-icons/ri";
import { BiMessageSquareAdd } from "react-icons/bi";
import { TiArrowSortedUp } from "react-icons/ti";

const iconStyle = "text-2xl mr-[10px]";

export const MENU_LIST = [
  {
    icon: <CgUserList className={iconStyle} />,
    text: "profile",
    link: "/profile",
  },
  {
    icon: <FaPiggyBank className={iconStyle} />,
    text: "Deposit",
    link: "deposit",
  },
  {
    icon: <AiFillDollarCircle className={iconStyle} />,
    text: "Withdraw",
    link: "withdraw",
  },

  {
    icon: <FaHistory className={iconStyle} />,
    text: "Bet Record",
    link: "betRecord",
  },
  {
    icon: <BsCashCoin className={iconStyle} />,
    text: "Transaction",
    link: "transaction",
  },
  {
    icon: <IoGift className={iconStyle} />,
    text: "Rewards",
    link: "/rewards",
  },
  {
    icon: <HiShare className={iconStyle} />,
    text: "Refer-Code",
    link: "/refer-code",
  },
  { icon: <TbMessage className={iconStyle} />, text: "Inbox", link: "inbox" },
  {
    icon: <IoSettings className={iconStyle} />,
    text: "Settings",
    link: "settings",
    border: true,
  },
];
