export const storeMemberInfo = (data) => {
  return {
    type: "storeMemberInfo",
    payload: data,
  };
};
export const cleanMemberInfo = () => {
  return {
    type: "cleanMemberInfo",
  };
};
export const StoreCryptoRatio = (data) => {
  return {
    type: "StoreCryptoRatio",
    payload: data,
  };
};
