import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { Select } from "semantic-ui-react";

import { commonOpacity } from "../../animateConstant";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import CommonBorder from "../../components/BorderComponent/CommonBorder";
import Record from "./components/Record";
import CommonTab from "../../components/TabComponent/CommonTab";
import { getFixedStyle } from "../../hook/useDeviceType";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TimePicker from "../../components/TimePicker/TimePicker";
import { formatTime, getToday } from "../../utils/getDay";
import { getGameReport } from "../../api/api";
import { notice } from "../../redux/action/action";
import {
  startLoading,
  stopLoading,
} from "../../redux/action/loading/loadingAction";
import { formatNumber } from "../../utils/formatNumber";
import LoaderComponent from "../../components/Loading/LoaderComponent";

const TAB = [
  {
    label: "Total Win",
    value: "Total Win",
  },
];

const BetRecord = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);
  const dispatch = useDispatch();

  const [tab, setTab] = useState(TAB[0].value);
  const [datePicker, setDatePicker] = useState([getToday(), getToday()]);
  const [gameRecord, setGameRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const gameArray = [];

  const getGameFullData = (data) => {
    for (let i = 0; i < data.length; i++) {
      gameArray.push(data[i].data);
    }
    return gameArray.flat();
  };

  useEffect(() => {
    if (datePicker.length === 1) {
      return;
    }
    setIsLoading(true);
    getGameReport({
      std: formatTime(datePicker[0]),
      etd: formatTime(datePicker[1]),
    })
      .then((data) => {
        if (data.code === "Ok") {
          setGameRecord(getGameFullData(data.report));
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [datePicker]);

  return (
    <motion.main
      initial={commonOpacity.initial}
      animate={commonOpacity.animate}
      exit={commonOpacity.exit}
      transition={commonOpacity.transition}
      className={`${getFixedStyle()} bg-second-color`}
    >
      <CloseDetailPage title={i18n("title")} />
      <section className="">
        <div className="flex items-center gap-[10px] px-common-padding-x pt-common-padding-y">
          <TimePicker datePicker={datePicker} setDatePicker={setDatePicker} />
        </div>
        <CommonBorder />
        <section className="flex items-center">
          {TAB.map((item, index) => {
            return (
              <CommonTab
                key={index}
                setTab={setTab}
                tab={tab}
                length={TAB.length}
                value={item.value}
                label={i18n(item.label?.toLowerCase())}
              />
            );
          })}
        </section>
      </section>
      <div className="h-[3px] w-full bg-extra-black"></div>
      <section className="">
        <LoaderComponent commonLoading={isLoading}> 
        
          {gameRecord.map((item, index) => {
            const { platform, winAmount, turnover, jpbet, jpwin } = item;
            return (
              
              <Record
                gameName={platform}
                jackpotWin={formatNumber(jpwin)}
                bet={formatNumber(turnover)}
                winLose={formatNumber(winAmount)}
              />
            );
          })}
        </LoaderComponent>
      </section>
    </motion.main>
  );
};

export default BetRecord;
