import React from "react";
import { Icon } from "semantic-ui-react";

const Arrow = ({ swiperRef, setOpenCardSetting, setCardInformation }) => {
  const handleSlideRight = () => {
    setOpenCardSetting(false);
    setCardInformation({});
    const swiper = swiperRef.current.swiper;
    if (swiperRef.current) {
      swiper.slideTo(swiper.activeIndex + 1);
    }
  };
  const handleSlideLeft = () => {
    setOpenCardSetting(false);
    setCardInformation({});
    const swiper = swiperRef.current.swiper;
    if (swiperRef.current) {
      swiper.slideTo(swiper.activeIndex - 1);
    }
  };

  return (
    <section className="flex justify-between w-[112%] absolute absolute-center z-[1]">
      <div className="w-[34px] h-[34px] flex items-center justify-center rounded-full bg-main-color ml-[5px]">
        <Icon
          onClick={handleSlideLeft}
          className="!text-light-white !mb-[5px] !ml-[3px] cursor-pointer"
          name="angle left"
        />
      </div>
      <div className="w-[34px] h-[34px] flex items-center justify-center rounded-full bg-main-color mr-[15px]">
        <Icon
          onClick={handleSlideRight}
          className="!text-light-white !mb-[5px] !ml-[3px] cursor-pointer"
          name="angle right"
        />
      </div>
    </section>
  );
};

export default Arrow;
