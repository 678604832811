import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { FILTER_CURRENCY_IMAGE } from "../../../currency";
import CurrencySymbol from "../../../utils/CureencySymbol";
import MemberBalance from "../../../utils/MemberBalance";
import SelectCurrency from "./SelectCurrency";
import { useSelector } from "react-redux";

const Balance = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const currencyImage = useMemo(() => {
    return FILTER_CURRENCY_IMAGE(memberData?.wallettype?.toLowerCase());
  }, [memberData?.wallettype]);

  const symbol = useMemo(() => {
    return memberData?.basedisplaycur;
  }, [memberData?.basedisplaycur]); //會員資料中的貨幣符號

  return (
    <div className="bg-coin-bg rounded-[6px] px-[12px] flex items-center gap-[5px] h-[36px] cursor-pointer relative">
      <p className="text-main-color mb-0">{CurrencySymbol(symbol)}</p>
      <p className="text-[#5f5f5f] max-w-[80px] text-overflow font-semibold mb-0">
        <MemberBalance />
      </p>
      <img className="w-[14px] h-[14px]" src={currencyImage} />
      <Icon className="text-[#5f5f5f] !mb-[5px]" name="caret down" />
      <SelectCurrency />
    </div>
  );
};

export default Balance;
