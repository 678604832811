import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import GetParams from "../../utils/getParams";
import GAMEITEM from "../GAMEITEM/GAMEITEM";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  startLoading,
  stopLoading,
} from "../../redux/action/loading/loadingAction";
import LoaderComponent from "../Loading/LoaderComponent";

const Games = ({ defaultPlatform, platformType }) => {
  const platform = GetParams("platform");

  const gameList = useSelector((state) => state.isGameList);

  const games = useMemo(() => {
    if (!platform) return gameList?.[defaultPlatform]?.[platformType];
    return gameList?.[platform]?.[platformType];
  }, [gameList, platform]);

  const [more, setMore] = useState(30);

  useEffect(() => {
    setMore(30);
    if (more >= games?.length) {
      setMore(games?.length);
    } else if (more < 30) {
      setMore(30);
    }
  }, [platform, games]);

  const handleMoreGame = () => {
    if (more < games?.length) {
      setMore((prev) => Math.min(prev + 30, games?.length));
    }
  };

  return (
    <section>
      <section className="grid md:grid-cols-5 lg:grid-cols-7 grid-cols-3 gap-[10px] mb-[10px]">
        {games?.slice(0, more)?.map((item, index) => {
          const en_Name = item?.ename;
          const zh_Name = item?.cname;
          const platform = item?.platform;
          const gameCode = item?.gameCode;
          const gameType = item?.gameType;
          return (
            <GAMEITEM
              index={index}
              hasBottomTitle
              gameProps={{ en_Name, zh_Name, platform, gameCode, gameType }}
            />
          );
        })}
      </section>
      <p className="text-main-color text-center text-xl font-semibold">
        {more}/{games?.length} 遊戲
      </p>
      {more >= games?.length || (
        <section
          onClick={handleMoreGame}
          className="flex flex-col items-center justify-center text-main-color mt-[10px] cursor-pointer"
        >
          <p className="mb-[1px] text-dark-gray-text">Show more</p>
          <RiArrowDownSLine className="text-3xl arrow-down text-dark-gray-text" />
        </section>
      )}
    </section>
  );
};

export default Games;
