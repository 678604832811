import { CgAddR } from "react-icons/cg";

const Add = () => {
  return (
    <section className="flex flex-col items-center justify-center py-[20px]">
      <CgAddR className="text-5xl text-light-white" />
      <p className="font-semibold text-light-white">No data...</p>
    </section>
  );
};

export default Add;
