import React from "react";
import MobilePop from "../../utils/MobilePop";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import { Dropdown } from "semantic-ui-react";
import LanguageSettings from "./components/LanguageSettings";
import { useTranslation } from "react-i18next";
import Wrapper from "./components/Wrapper";
import Text from "./components/Text";

const Settings = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);

  return (
    <MobilePop>
      <div className="flex flex-col h-full justify-between">
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-end`}
          >
            <CloseComponent />
          </div>
          <p className="mb-0 text-3xl font-bold text-main-color text-center mb-[20px]">
            {i18n("title")}
          </p>
          <section className="px-[20px]">
            <Wrapper>
              <Text text={i18n("setLanguage")} />
              <LanguageSettings />{" "}
            </Wrapper>
          </section>
        </section>
      </div>
    </MobilePop>
  );
};

export default Settings;
