import { useNavigate } from "react-router";
import { capitalizeFirstLetter } from "../../../replace_str_constant";

const SelectMethod = ({
  method,
  currency,
  setChooseCurrency,
  setWithdrawStep,
  setWithdrawData,
  data,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="px-[24px] py-[10px] bg-game-background rounded-xl flex items-center justify-between mb-[10px] cursor-pointer"
      onClick={() => {
        setWithdrawData(data);
        setChooseCurrency(currency);
        navigate(`?method=${method}`);
        setWithdrawStep(2);
      }}
    >
      <p className="text-light-white font-bold">
        {capitalizeFirstLetter(method)}
        &nbsp;
        {`(${currency})`}
      </p>
    </div>
  );
};

export default SelectMethod;
