import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { LANGUAGES, languageMapping } from "../../../i18n/config";
import i18next from "i18next";

const LanguageSettings = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Dropdown
      button
      className="icon !w-full"
      floating
      labeled
      icon={{
        name: "world",
        className: "!text-light-white !bg-main-color",
      }}
      search
      text={languageMapping(i18next?.language)}
    >
      <Dropdown.Menu>
        {LANGUAGES.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={(event) => {
                event.stopPropagation();
                i18n.changeLanguage(item.language);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSettings;
