import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { PROMOTION_BANNER_BACKGROUND, PROMOTION_LIST } from "./PromotionList";
import { commonOpacity } from "../../animateConstant";
import { goToDetailPage } from "../../redux/action/action";
import { getPromotionList } from "../../api/api";
import Empty from "../../components/Empty/Empty";
import { Base64 } from "js-base64";

const Promotion = () => {
  const [promotionList, setPromotionList] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getPromotionList()
      .then((data) => {
        if (data.code === "Ok") {
          console.log(data);
          setPromotionList(data.promotion);
        } else {
        }
      })
      .finally(() => {});
  }, []);

  return (
    <motion.main className="px-common-padding-x py-common-padding-y">
      <p className="text-center font-semibold text-lg text-white lg:text-5xl">
        Promotions
      </p>
      <section className="mt-[16px] md:grid grid-cols-3 gap-[10px]">
        {promotionList.length === 0 ? (
          <Empty />
        ) : (
          promotionList.map((promotion, index) => {
            const sectionColor = PROMOTION_BANNER_BACKGROUND[index % 3];
            const uid = promotion.uid;
            const title = promotion.title;
            const date = promotion.createDate;
            const imgLink = promotion.img;
            const encodeMessage = promotion.content;

            const message = JSON.stringify(Base64.decode(promotion.content));
            const parser = new DOMParser();
            const htmlDocument = parser.parseFromString(message, "text/html");
            const textContent =
              htmlDocument.body.textContent.slice(1, -1) || "";

            return (
              <section
                key={index}
                className="w-full rounded-[10px] bg-third-color min-h-[145px] p-[16px] relative cursor-pointer mb-[15px]"
                onClick={() => {
                  navigate(
                    `promotioncontent?title=${title}&content=${encodeMessage}&date=${date}&link=${Base64.encode(
                      imgLink
                    )}`
                  );
                }}
              >
                <div className="flex flex-col w-[40%] max-h-[145px] overflow-hidden">
                  <p className="text-white text-lg mb-[5px] font-semibold">
                    {title}
                  </p>
                  <div
                    className="text-light-text text-sm font-light break-all text-overflow"
                    style={{ whiteSpace: "unset" }}
                  >
                    {textContent}
                  </div>
                </div>
                <div className="overflow-hidden absolute top-0 left-0 w-full h-full rounded-[10px]">
                  <span
                    className="absolute right-0 h-[150px] w-[150px] blur-[50px]"
                    style={{
                      background: sectionColor,
                    }}
                  ></span>
                </div>
                <img
                  className="w-[150px] h-[160px] absolute top-0 right-0 object-cover"
                  src={promotion.background}
                />
              </section>
            );
          })
        )}
      </section>
    </motion.main>
  );
};

export default Promotion;
