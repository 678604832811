import SLOT from "../../images/GameSelect/SLOT.png";
import LIVE from "../../images/GameSelect/LIVE.png";
import FISH from "../../images/GameSelect/FISH.png";
import SPORT from "../../images/GameSelect/SPORTS.png";
import EGAME from "../../images/GameSelect/EGAME.png";
import RNG from "../../images/GameSelect/RNG.png";

export const GAME_LIST = [
  { icon: SLOT, text: "SLOT", link: "/slot", title: "Slot" },
  { icon: LIVE, text: "LIVE", link: "/live", title: "Live" },
  { icon: FISH, text: "FH", link: "/fish", title: "Fish" },
  { icon: SPORT, text: "SPORT", link: "/sports", title: "Sports" },
  { icon: EGAME, text: "EGAME", link: "/egame", title: "Egame" },
  { icon: RNG, text: "TABLE", link: "/rng", title: "Rng" },
];
