import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import CommonTab from "../../components/TabComponent/CommonTab";
import CommonBorder from "../../components/BorderComponent/CommonBorder";
import { CURRENCY_SYMBOL } from "../../constant";
import { commonOpacity } from "../../animateConstant";
import { getFixedStyle } from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import TimePicker from "../../components/TimePicker/TimePicker";
import { formatTime, getToday } from "../../utils/getDay";
import { getCashReport } from "../../api/api";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import {
  startLoading,
  stopLoading,
} from "../../redux/action/loading/loadingAction";
import Record from "./components/Record";
import { formatNumber } from "../../utils/formatNumber";
import { capitalizeFirstLetter } from "../../replace_str_constant";

const TAB = [
  { label: "Deposit", value: "Deposit" },
  { label: "Withdraw", value: "Withdraw" },
];

const Transaction = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);

  const [tab, setTab] = useState(TAB[0].value);
  const [datePicker, setDatePicker] = useState([getToday(), getToday()]);
  const [cashRecord, setCashRecord] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (datePicker.length === 1) {
      return;
    }
    getCashReport({
      std: formatTime(datePicker[0]),
      etd: formatTime(datePicker[1]),
    })
      .then((data) => {
        if (data.code === "Ok") {
          setCashRecord(data.data);
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {});
  }, [datePicker]);

  return (
    <motion.main
      initial={commonOpacity.initial}
      animate={commonOpacity.animate}
      exit={commonOpacity.exit}
      transition={commonOpacity.transition}
      className={` ${getFixedStyle()} bg-second-color `}
    >
      <CloseDetailPage title={i18n("title")} />
      <section className="py-common-padding-y p-[15px]">
        <div className="flex items-center gap-[10px] px-common-padding-x pt-common-padding-y">
          <TimePicker datePicker={datePicker} setDatePicker={setDatePicker} />
        </div>
        <CommonBorder />
        <section className="flex items-center">
          {TAB.map((item, index) => {
            return (
              <CommonTab
                key={index}
                setTab={setTab}
                tab={tab}
                label={i18n(item.label?.toLowerCase())}
                value={item.value}
              />
            );
          })}
        </section>
        <CommonBorder notMargin />
        <section>
          {cashRecord
            .filter((item) => item.action === tab?.toLowerCase())
            .map((item, index) => {
              const method = item.action; //方式
              const date = item.date; //日期
              const yearAndMonth = date.slice(0, 7); //年月
              const point = item.point; //金额
              const lastBalace = item.lastbalance; //目前余额
              return (
                <>
                  {index === 0 ||
                  yearAndMonth !== cashRecord[index - 1]?.date?.slice(0, 7) ? (
                    <p className="text-light-text text-lg mb-0 bg-extra-black font-semibold px-common-padding-x">
                      {yearAndMonth}
                    </p>
                  ) : null}
                  
                  <Record
                    single={index % 2 === 0}
                    key={index}
                    method={capitalizeFirstLetter(method)}
                    time={date}
                    amounts={formatNumber(point)}
                    lastBalance={formatNumber(lastBalace)}
                  />
                </>
              );
            })}
        </section>
      </section>
    </motion.main>
  );
};

export default Transaction;
