import { AnimateSharedLayout, motion } from "framer-motion";
import { animatePage } from "../../animateConstant";
import { HiChevronLeft } from "react-icons/hi";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Select } from "semantic-ui-react";
import InputCustom from "../../components/InputComponents/InputCustom";
import { CHAIN_LIST, CRYPTO_LIST } from "../../currency";
import { useDispatch, useSelector } from "react-redux";
import { formatEightNumber, formatNumber } from "../../utils/formatNumber";
import ErrorHint from "../../components/Hint/ErrorHint";
import { withdrawFund } from "../../api/api";
import { notice } from "../../redux/action/action";

const WithdrawStepTwoCrypto = ({
  chooseCurrency,
  withdrawStep,
  setWithdrawStep,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);

  const cryptoRatio = useSelector((state) => state.isCryptoRatio);
  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [dataFocus, setDataFocus] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleFocus = (e) => {
    setDataFocus({ [e.target.name]: true });
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    withdrawFund({
      amounts: formatEightNumber(
        data.amounts / cryptoRatio?.[chooseCurrency?.toLowerCase()]
      ),
      real_name: memberData?.bank_name,
      bank_payWay: "-",
      bank_branch: "-",
      bank_account: data?.cryptoAddress,
      currency: data?.cryptoType,
      cryptoChain: data?.cryptoChain,
      type: "Crypto Withdraw",
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(notice(data.msg, "success"));
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <AnimateSharedLayout>
      <motion.section
        className="h-full flex flex-col justify-between overflow-y-scroll no-scrollbar"
        layout
        initial={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-between`}
          >
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => setWithdrawStep(1)}
            />
            <CloseComponent />
          </div>
          <p className="text-3xl text-main-color font-extrabold text-center">
            {i18n("withdraw")}
          </p>

          <section>
            <p className="text-center text-light-text my-[20px]">
              {i18n("enterdetail")}
              {chooseCurrency}.
            </p>

            <p className="text-xl font-extrabold text-main-color">
              {i18n("withdraw")}
              {chooseCurrency} {i18n("now")}.
            </p>
            <section>
              <section className="mb-[24px]">
                <div className="flex justify-center items-center my-2">
                  <div className="m-2 w-[50%]">
                    <p className="mb-1 font-semibold">請選擇貨幣</p>
                    <Select
                      className="!bg-input-color w-full custom-select mb-[10px] !min-w-[5em] w-auto"
                      placeholder={`請選擇貨幣`}
                      name="cryptoType"
                      onChange={(event, { value }) => {
                        setData({ ...data, cryptoType: value });
                      }}
                      options={CRYPTO_LIST}
                    />
                  </div>
                  <div className="m-2 w-[50%]">
                    <p className="mb-1 font-semibold">請選擇網絡</p>
                    <Select
                      className="!bg-input-color w-full custom-select mb-[10px] !min-w-[5em] w-auto"
                      placeholder={`請選擇網絡`}
                      name="cryptoChain"
                      onChange={(event, { value }) => {
                        setData({ ...data, cryptoChain: value });
                      }}
                      options={CHAIN_LIST}
                    />
                  </div>
                </div>
                <div className="m-2">
                    <p className="mb-1 font-semibold">請輸入地址</p>
                  <InputCustom
                    placeholder={`Enter your withdraw address`}
                    type="text"
                    name="cryptoAddress"
                    setDataProps={setData}
                    dataProps={data}
                    onChange={(e) => handleChange(e)}
                    onFocus={(e) => handleFocus(e)}
                    focusProps={dataFocus}
                  />
                </div>
                <div className="m-2">
                  <p className="mb-1 font-semibold">金額</p>
                  <InputCustom
                    placeholder={`Enter your ${chooseCurrency} amounts`}
                    type="text"
                    name="amounts"
                    setDataProps={setData}
                    dataProps={data}
                    onChange={(e) => {
                      setData({
                        ...data,
                        amounts: e.target.value,
                      });
                    }}
                    onFocus={(e) => handleFocus(e)}
                    focusProps={dataFocus}
                  />
                </div>
                {data?.amounts && (
                  <ErrorHint>
                    Approximately PHP currency value:
                    {formatNumber(
                      data?.amounts *
                        cryptoRatio?.[chooseCurrency?.toLowerCase()]
                    )}
                  </ErrorHint>
                )}
              </section>
            </section>
            {/* <section className="mb-[10px]"></section> */}
          </section>
        </section>
        <section>
          {data.amounts && (
            <p className="text-light-text">
              {chooseCurrency}{" "}
              <span className="text-main-color font-bold">{data.amounts}</span>{" "}
              In your personal account
            </p>
          )}
          <Button
            loading={buttonLoading}
            disabled={buttonLoading}
            className="!bg-main-color !text-white w-full"
            content={i18n("withdraw")}
            onClick={() => handleSubmit()}
          />
        </section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default WithdrawStepTwoCrypto;
