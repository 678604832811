import { useEffect, useState } from "react";
import { HashRouter, Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../src/styles/App.css";
import "./i18n/i18n";

import LoadingPage from "./components/Loading/LoadingPage";
import ProgressBlock from "./components/ProgressBlock/ProgressBlock";
import { common_route, routes } from "./utils/routes";
import OverflowHidden from "./utils/OverflowHidden";
import ShouldHideMenu from "./utils/hideMenu";
import { updateDataPeriodically } from "./api/updateDataPeriodically";
import MenuBar from "./pages/MenuBar/MenuBar";
import useDeviceType from "./hook/useDeviceType";
import { API_RELOAD } from "./constant";
import { InitializeApi } from "./api/initializeApi";
import ShowGameDetail from "./components/ShowGameDetail";

function App() {
  const deviceType = useDeviceType();
  const hideMenu = ShouldHideMenu();

  const dispatch = useDispatch();

  useEffect(() => {
    InitializeApi(dispatch)();
  }, []);

  useEffect(() => {
    updateDataPeriodically(dispatch)();

    const apiIterval = setInterval(() => {
      updateDataPeriodically(dispatch)();
    }, API_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  return (
    <main className="">
      <HashRouter>
        {/* <MenuBar /> */}
        <section
          className={`${
            deviceType === "PC"
              ? hideMenu
                ? "pl-[64px]  w-full"
                : "pl-[320px] w-full"
              : ""
          }`}
        >
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element}>
                  {common_route?.map((child, index) => {
                    return (
                      <Route
                        key={index}
                        path={`${child.path}`}
                        element={
                          <OverflowHidden>{child.element}</OverflowHidden>
                        }
                      />
                    );
                  })}
                </Route>
              );
            })}
          </Routes>
        </section>
      </HashRouter>
      <LoadingPage />
      <ProgressBlock />
    </main>
  );
}

export default App;
