import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import DepositStepTwo from "./DepositStepTwo";
import SelectMethod from "./components/SelectMethod";
import DepositStepOne from "./DepositStepOne";
import { depositMethodList } from "../../api/api";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import DepositStepTwoCrypto from "./DepositStepTwoCrypto";
import { useSelector } from "react-redux";
import MethodToggle from "./components/MethodToggle";
import { useNavigate } from "react-router";

const Deposit = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const navigate = useNavigate();

  const [chooseCurrency, setChooseCurrency] = useState("PHP");
  const [depositStep, setDepositStep] = useState(1);
  const [depositMethod, setDepositMethod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    depositMethodList()
      .then((data) => {
        if (data.code === "Ok") {
          setDepositMethod(data.paylist);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <main className="fixed top-0 left-0 w-full h-full z-[9999] bg-[rgb(0,0,0,0.6)] flex items-center justify-center px-common-padding-x">
      <section className="bg-second-color max-h-[70vh] h-full w-[660px] rounded-xl py-10 px-2 pb-[1rem] overflow-y-scroll no-scrollbar">
        <section className="flex justify-center">
          <MethodToggle />
        </section>
        {memberData?.wallettype === "usdt" ? (
          <DepositStepTwoCrypto chooseCurrency={`USDT`} />
        ) : (
          <>
            {depositStep === 1 && (
              <DepositStepOne
                setDepositStep={setDepositStep}
                setChooseCurrency={setChooseCurrency}
                depositStep={depositStep}
                depositMethod={depositMethod}
                isLoading={isLoading}
              />
            )}
            {depositStep === 2 && (
              <DepositStepTwo
                setDepositStep={setDepositStep}
                chooseCurrency={chooseCurrency}
                depositStep={depositStep}
              />
            )}
          </>
        )}
      </section>
    </main>
  );
};

export default Deposit;
