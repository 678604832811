import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/swiper-bundle.css";
import Card from "./Card";
import Arrow from "./Arrow";
import Empty from "../../../../components/Empty/Empty";
import { filterCardTag } from "./utils/filterTag";
import { useTranslation } from "react-i18next";

const SwiperCard = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);

  const {
    setOpenCardSetting,
    setCardInformation,
    cardNumber,
    setData,
    data,
    noSettings, //沒有設定銀行卡
  } = props;

  const swiperRef = useRef();

  const memberData = useSelector((state) => state.isMemberInfo);

  useEffect(() => {
    if (typeof setData === "function") {
      setData({
        [`bank_name`]: memberData?.[`bank_name`],
        [`bank_acc`]: memberData?.[`bank_acc`],
        [`bank_code`]: memberData?.[`bank_code`],
        [`bank_num`]: memberData?.[`bank_num`],
      });
    }
  }, []);

  return (
    <Swiper
      ref={swiperRef}
      className="mySwiper relative !overflow-visible md:w-[80%]"
      autoplay={{
        disableOnInteraction: false,
      }}
      onSlideChange={(swiper) => {
        const card = filterCardTag(swiper.activeIndex);
        setData({
          ...data,
          [`bank_name`]: memberData?.[`bank_name${card}`],
          [`bank_acc`]: memberData?.[`bank_acc${card}`],
          [`bank_code`]: memberData?.[`bank_code${card}`],
          [`bank_num`]: memberData?.[`bank_num${card}`],
        });
      }}
      onSwiper={(swiper) => swiper}
      spaceBetween={20}
    >
      {cardNumber.map((card, index) => {
        return !memberData?.[`bank_num${card}`] ? (
          <SwiperSlide key={index}>
            <section
              className="mt-[40px]"
              onClick={() => setOpenCardSetting(true)}
            >
              <Empty />
              <p className="underline text-center text-light-white">
                {i18n("card.addCard")}
              </p>
            </section>
          </SwiperSlide>
        ) : (
          <SwiperSlide key={index}>
            <Card
              noSettings={noSettings}
              cardNumber={card}
              setCardInformation={setCardInformation}
              setOpenCardSetting={setOpenCardSetting}
            />
          </SwiperSlide>
        );
      })}
      <Arrow
        setCardInformation={setCardInformation}
        setOpenCardSetting={setOpenCardSetting}
        swiperRef={swiperRef}
      />
    </Swiper>
  );
});

export default SwiperCard;
