import { notice } from "../redux/action/action";
import { storeGameList } from "../redux/action/game/gameAction";
import {
  startLoading,
  stopLoading,
} from "../redux/action/loading/loadingAction";
import { storeMemberInfo } from "../redux/action/member/memberAction";
import UserLogout from "./UserLogout";
import { checkOauth, getGameList, getMemberInfo } from "./api";

export const InitializeApi = (dispatch) => async () => {
  try {
    dispatch(startLoading());
    const [gameListData, checkOauthData] = await Promise.all([
      getGameList(),
      checkOauth(),
    ]);
    if (checkOauthData.code === "Ok") {
      window.sessionStorage.setItem("sess_911betnet", checkOauthData.oauth);
    }

    if (gameListData.code === "Ok") {
      dispatch(storeGameList(gameListData.games));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(stopLoading());
  }
};
