import React from "react";
import { useSelector } from "react-redux";
import { Dimmer, Image, Loader, Segment } from "semantic-ui-react";

const LoaderComponent = ({ children, commonLoading }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  return loadingStatus || commonLoading ? (
    <Segment className="!border-0">
      <Dimmer active inverted>
        {" "}
        <Loader inverted inline="centered"></Loader>
      </Dimmer>
      <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
    </Segment>
  ) : (
    <>{children}</>
  );
};

export default LoaderComponent;
