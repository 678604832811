import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const method = [
  { label: "Deposit", value: "deposit" },
  { label: "Withdraw", value: "withdraw" },
];

const MethodToggle = () => {
  const location = useLocation();
  console.log(location);
  const [methods, setMethods] = useState(
    location.pathname === "/deposit" ? "deposit" : "withdraw"
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (methods === "withdraw") {
      navigate("/withdraw");
    } else {
      navigate("/deposit");
    }
  }, [methods]);

  return (
    <section className="p-[3px] rounded-full flex items-center bg-coin-bg">
      {method.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => setMethods(item.value)}
            className={`flex items-center ${
              methods === item.value && "bg-main-color"
            }  text-light-white justify-center py-[5px] px-[10px] rounded-full mr-[3px]`}
          >
            {/* <p className="mb-0 font-semibold">{item.label}</p> */}
            <p className={`mb-0 font-semibold ${
              methods !== item.value && "text-dark-gray-text"}`}
              >{item.label}</p>
          </div>
        );
      })}
    </section>
  );
};

export default MethodToggle;
