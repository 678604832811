const CommonTab = ({ key, setTab, tab, value, label, length }) => {
  return (
    <div
      key={key}
      onClick={() => setTab(value)}
      className={`${
        length > 1 && "max-w-[50%]"
      } w-full text-lg cursor-pointer ${
        tab === value
          ? "text-main-color border-b-2 border-main-color"
          : "text-dark-gray-text"
      } font-semibold`}
    >
      <p className="text-center pb-[10px] ">{label}</p>
    </div>
  );
};

export default CommonTab;
