import { useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

import InputComponents from "../../components/InputComponents/InputComponents";
import { getMemberInfo, loginUser } from "../../api/api";
import { useDispatch } from "react-redux";
import { loading, loadingDestroy, notice } from "../../redux/action/action";
import { cryptData } from "../../utils/encryptData";
import { COMMON_LOADING } from "../../constant";
import { useLocation, useNavigate } from "react-router";
import { storeMemberInfo } from "../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";

const PhoneSignin = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const [watchPassword, setWatchPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState({});
  const { loginName, password } = data;
  const [dataFocus, setDataFocus] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const handleLoginUser = (e) => {
    e.preventDefault();
    dispatch(loading());
    setButtonLoading(true);

    loginUser({
      loginName: loginName,
      password: cryptData(password),
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(notice(data.msg, "success"));
          if (window.sessionStorage.getItem("sess_911betnet")) {
            window.sessionStorage.removeItem("sess_911betnet");
          }
          window.sessionStorage.setItem("sess_911betnet", data.oauth);

          getMemberInfo().then((data) => {
            if (data.code === "Ok") {
              dispatch(storeMemberInfo(data));
            }
          });

          navigate("/" + location.search);
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
        dispatch(loadingDestroy());
      });
  };

  const inputList = [
    {
      label: "phone",
      name: "loginName",
      type: "text",
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
    },
    {
      label: "password",
      name: "password",
      type: watchPassword ? "text" : "password",
      needIcon: true,
      icon1: watchPassword ? "eye slash" : "eye",
      function: () => setWatchPassword(!watchPassword),
      onChange: (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      },
    },
  ];

  return (
    <section>
      {inputList.map((input, index) => {
        return (
          <InputComponents
            focusProps={dataFocus}
            dataProps={data}
            setDataProps={setData}
            inputProps={input}
            index={index}
          />
        );
      })}
      <p
        onClick={() => navigate("/forgetpassword")}
        className="mb-0 text-light-white text-sm text-end"
      >
        Forget password ?
      </p>
      <Button
        className="w-full !bg-main-color !mt-[30px]"
        disabled={buttonLoading}
        loading={buttonLoading}
        onClick={handleLoginUser}
        type="submit"
      >
        {i18n("signin")}
      </Button>
    </section>
  );
};

export default PhoneSignin;
