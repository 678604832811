import { motion } from "framer-motion";
import React from "react";
import { commonOpacity, popUpVariant } from "../animateConstant";

const MobilePop = ({ children }) => {
  return (
    <section className="fixed top-0 left-0 w-full h-full z-[9999] bg-[rgb(0,0,0,0.6)] flex items-center justify-center px-common-padding-x">
      <motion.section
        initial={popUpVariant.initial}
        animate={popUpVariant.animate}
        exit={popUpVariant.exit}
        transition={popUpVariant.transition}
        className="bg-second-color max-h-[70vh] h-full w-[660px] rounded-xl px-[.5rem] pt-[.5rem] pb-[1rem]"
      >
        {children}
      </motion.section>
    </section>
  );
};

export default MobilePop;
