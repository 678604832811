import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Dropdown, Icon, Input } from "semantic-ui-react";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { CURRENCY_LIST } from "../currency";
import { popUpVariant } from "../animateConstant";
import { loading, loadingDestroy, notice } from "../redux/action/action";
import GetParams from "../utils/getParams";
import { enterGame } from "../api/api";
import { REPLACE_AIR } from "../replace_str_constant";
import { closeGame, storeGameLink } from "../redux/action/game/gameAction";
import { isLoggedIn } from "../utils/isLoggedIn";
import i18next from "i18next";

import usdt from "../images/Currency/tether.png";
import peso from "../images/Currency/peso.png";
import dong from "../images/Currency/dong.png";

const ShowGameDetail = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gameStatus = useSelector((state) => state.isGameOpen);

  const location = useLocation();

  const [buttonLoading, setButtonLoading] = useState(false);
  const dropdownRef = useRef(null); // 建立 ref 來存取 Dropdown
  const currencyOptions = [
    { value: 'VND', label: 'VND', icon: '../images/Currency/dong.png' },
    { value: 'PHP', label: 'PHP', icon: '../images/Currency/peso.png' }, 
    // 其他貨幣選項
  ];
  const CustomDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('VND');
    const currencyInfo = CURRENCY_LIST.find((item) => item.value === selectedCurrency);
  const dropdownRef = useRef(null);
  
    const handleCurrencyChange = (value) => {
      setSelectedCurrency(value);
      setIsOpen(false);
    };
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div>
        <div className="flex items-center relative inline-block">
          <div className="mr-2">余額</div>
          <div
            className="my-2 p-2 border border-gray-300 rounded-md cursor-pointer flex items-center"
            onClick={toggleDropdown}
          >
            <div className="flex items-center mr-2">
              {currencyInfo && (
                <img
                  src={currencyInfo.icon}
                  alt={currencyInfo.label}
                  className="w-6 h-6 mr-2"
                />
              )}
              <span>{currencyOptions.find((option) => option.value === selectedCurrency)?.label}</span>
            </div>
            <Icon name={isOpen ? 'caret up' : 'caret down'} />
          </div>
  
          {isOpen && (
            <div className="absolute mt-1 bg-white shadow-lg rounded-md py-1 z-10 !right-[0px] !top-[40px]" ref={dropdownRef}>
              {currencyOptions.map((option) => {
              const icon = option.icon; // 貨幣圖片
              return (
                <div
                  key={option.value}
                  className={`cursor-pointer flex items-center p-2 hover:bg-gray-200 ${
                    option.value === selectedCurrency ? 'font-semibold' : ''
                  }`}
                  onClick={() => handleCurrencyChange(option.value)}
                >
                  <img
                    src={option.icon}
                    alt={option.label}
                    className="w-6 h-6 mr-2"
                  />
                  <span>{option.label}</span>
                </div>
              );
            })}
            </div>
          )}
        </div>
      </div>
    );
  };
  const platform = GetParams("platform");
  const code = GetParams("code");
  const type = GetParams("type");
  const en_Name = GetParams("ename");
  const cn_Name = GetParams("cname");

  const handleEnterGame = () => {
    dispatch(loading());
    setButtonLoading(true);

    enterGame({ platform: platform, gameCode: code, gameType: type })
      .then((data) => {
        if (data.code === "Ok") {
          setButtonLoading(false);
          dispatch(storeGameLink(data.url));
          navigate("/gamebox");
        } else {
          dispatch(notice(data.msg, "error"));
          setButtonLoading(false);
        }
      })
      .finally(() => {
        dispatch(loadingDestroy());
      });
  };

  return (
    <AnimatePresence>
      {gameStatus && (
        <main className="fixed top-0 left-0 w-full h-full z-[9999] bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
          <motion.section
            variants={popUpVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{
              duration: 0.2,
            }}
            className="absolute sm:relative sm:w-[500px] sm:min-h-[400px] bottom-0 left-0 w-full min-h-[250px] rounded-t-xl bg-footer-bg py-[15px] px-[20px] flex flex-col justify-around z-[999999]"
          >
            <section className="flex items-start justify-between mb-[10px]">
              <section className="flex ">
                <div
                  style={{
                    backgroundImage: `url(./images-webp/gameIcon/en/${platform}/${en_Name}.webp)`,
                    backgroundSize: "100% 100%",
                  }}
                  className={`w-[140px] h-[140px] rounded-[10px] bg-center bg-no-repeat mr-[15px]`}
                />
                <div className="flex flex-col text-dark-gray-text">
                  <p className="text-xl font-bold mb-[5px]">
                    {i18next.language === "en" ? en_Name : cn_Name}
                  </p>
                  <p className="text-light-text text-sm mb-[20px]">
                    {platform}
                  </p>
                  <HiStar className="text-3xl text-light-text" />

                  <CustomDropdown />
                  
                </div>
              </section>
              <IoMdClose
                onClick={() => {
                  dispatch(closeGame());
                }}
                className="text-3xl text-light-text cursor-pointer"
              />
            </section>
            <section className="flex items-center justify-between">
              <Button
                disabled={buttonLoading}
                className="!bg-second-color !text-main-color w-full !mr-[10px]"
                onClick={() => {
                  handleEnterGame();
                }}
              >
                Test mode
              </Button>
              <Button
                disabled={buttonLoading}
                className="!bg-main-color w-full"
                onClick={() => {
                  if (!isLoggedIn()) {
                    navigate("/signin" + location.search);
                    return;
                  }

                  handleEnterGame();
                }}
              >
                Real mode
              </Button>
            </section>
          </motion.section>
        </main>
      )}
    </AnimatePresence>
  );
};

export default ShowGameDetail;
