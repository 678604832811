import { Outlet } from "react-router";
import Wrapper from "../components/Wrapper";
import Home from "../pages/Home/Home";
import Signup from "../pages/Signup/Signup";
import Signin from "../pages/Singin/Signin";
import Promotion from "../pages/Promotion/Promotion";
import ReferCode from "../pages/ReferCode/ReferCode";
import Deposit from "../pages/Deposit/Deposit";
import PromotionDetail from "../pages/Promotion/PromotionDetail";
import BetRecord from "../pages/BetRecord/BetRecord";
import Transaction from "../pages/Transaction/Transaction";
import Inbox from "../pages/Inbox/Inbox";
import InboxDetail from "../pages/Inbox/InboxDetail";
import Rewards from "../pages/Rewards/Rewards";
import Vip from "../pages/Vip/Vip";
import DownloadApp from "../pages/App/DownloadApp";
import Task from "../pages/Task/Task";
import CheckIn from "../pages/CheckIn/CheckIn";
import Authenticator from "./Authenticator";
import GameBox from "../pages/Game/GameBox";
import MenuBar from "../pages/MenuBar/MenuBar";
import OverflowHidden from "./OverflowHidden";
import DepositIframe from "../pages/Deposit/DepositIframe";
import Withdraw from "../pages/Withdraw/Withdraw";
import WalletSetting from "../components/SignInUp/components/WalletSetting";
import Settings from "../pages/Settings/Settings";
import Slot from "../pages/Slot/Slot";
import Live from "../pages/Live/Live";
import Fish from "../pages/Fish/Fish";
import Arcade from "../pages/Arcade/Arcade";
import RNG from "../pages/RNG/RNG";
import Sports from "../pages/Sports/Sports";
import Profile from "../pages/Profile/Profile";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import ScrollToTop from "./ScrollToTop";

export const common_route = [
  {
    path: "forgetpassword",
    label: "forgetpassword",
    element: <ForgetPassword />,
  },

  {
    path: "signup",
    label: "signup",
    element: <Signup />,
  },
  {
    path: "signin",
    label: "signin",
    element: <Signin />,
  },
  {
    path: "deposit",
    label: "deposit",
    element: <Deposit />,
  },
  {
    path: "betRecord",
    label: "betRecord",
    element: <BetRecord />,
  },
  {
    path: "transaction",
    label: "transaction",
    element: <Transaction />,
  },
  {
    path: "inbox",
    label: "inbox",
    element: <Inbox />,
  },
  {
    path: "inbox/:inboxId",
    label: "inbox",
    element: <InboxDetail />,
  },
  {
    path: "promotioncontent",
    label: "promotions",
    element: <PromotionDetail />,
  },
  {
    path: "app",
    label: "app",
    element: <DownloadApp />,
  },
  {
    path: "checkin",
    label: "checkin",
    element: <CheckIn />,
  },
  {
    path: "deposit/depositfund",
    label: "depositfund",
    element: <DepositIframe />,
  },
  {
    path: "withdraw",
    label: "withdraw",
    element: <Withdraw />,
  },
  {
    path: "walletsettings",
    label: "walletsettings",
    element: <WalletSetting />,
  },
  {
    path: "settings",
    label: "settings",
    element: <Settings />,
  },
];

export const routes = [
  {
    path: "/",
    label: "home",
    element: (
      <div>
        <Wrapper>
          <ScrollToTop>
            <Home />
          </ScrollToTop>
        </Wrapper>
        <Outlet />
      </div>
    ),
  },
  {
    path: "/promotion",
    label: "promotions",
    element: (
      <Wrapper>
        <Promotion />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/refer-code",
    label: "refer-code",
    element: (
      <Wrapper>
        <Authenticator>
          <ScrollToTop>
            <ReferCode />
            <Outlet />
          </ScrollToTop>
        </Authenticator>
      </Wrapper>
    ),
  },
  {
    path: "/rewards",
    label: "rewards",
    element: (
      <Wrapper>
        <Authenticator>
          <Rewards />
          <Outlet />
        </Authenticator>
      </Wrapper>
    ),
  },
  {
    path: "/vip",
    label: "vip",
    element: (
      <Wrapper>
        <Vip />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/task",
    label: "task",
    element: (
      <Wrapper>
        <Task />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/gamebox",
    label: "gameBox",
    element: (
      <Wrapper>
        <OverflowHidden closePop>
          <GameBox />
          <Outlet />
        </OverflowHidden>
      </Wrapper>
    ),
  },
  {
    path: "/slot",
    label: "slot",
    element: (
      <Wrapper>
        <Slot />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/live",
    label: "live",
    element: (
      <Wrapper>
        <Live />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/fish",
    label: "fish",
    element: (
      <Wrapper>
        <Fish />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/egame",
    label: "egame",
    element: (
      <Wrapper>
        <Arcade />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/rng",
    label: "rng",
    element: (
      <Wrapper>
        <RNG />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/sports",
    label: "sports",
    element: (
      <Wrapper>
        <Sports />
        <Outlet />
      </Wrapper>
    ),
  },
];
