import { AnimatePresence, motion } from "framer-motion";
import { Input } from "semantic-ui-react";
import SearchBox from "./SearchBox";
import { useEffect, useRef, useState } from "react";

const SearchItem = ({ setSearchItem, searchItem }) => {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <AnimatePresence>
      <section className="w-full min-h-[40px]">
        <div
          onClick={() => {
            setSearchItem(false);
          }}
          className="fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-[1999]"
        ></div>
        <motion.div
          initial={{ transform: "scaleX(0)" }}
          animate={{ transform: "scaleX(1)" }}
          transition={{ duration: 0.2, ease: "" }}
          style={{ transformOrigin: "top right" }}
          className="h-full py-common-padding-y w-full relative z-[2000]"
        >
          <Input
            ref={inputRef}
            iconPosition="left"
            onChange={(e) => setSearchValue(e.target.value)}
            className="w-full"
            icon={{ name: "search", circular: true, link: true }}
            input={{ className: "!bg-third-color" }}
            placeholder="The search term must be longer than three characters."
          ></Input>
        </motion.div>
      </section>
      <SearchBox searchValue={searchValue} />
    </AnimatePresence>
  );
};

export default SearchItem;
