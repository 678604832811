import { useEffect, useState } from "react";
import WithdrawStepOne from "./WithdrawStepOne";
import WithdrawStepTwo from "./WithdrawStepTwo";
import { withdrawMethodList } from "../../api/api";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import MethodToggle from "../Deposit/components/MethodToggle";
import { useSelector } from "react-redux";
import WithdrawStepTwoCrypto from "./WithdrawStepTwoCrypto";
import WithdrawStepTwoBankCard from "./WithdrawStepTwoCard";
import GetParams from "../../utils/getParams";

const Withdraw = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const [chooseCurrency, setChooseCurrency] = useState("PHP");
  const [withdrawStep, setWithdrawStep] = useState(1);
  const [withdrawMethod, setWithdrawMethod] = useState([]);
  const [withdrawData, setWithdrawData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const method = GetParams("method");

  useEffect(() => {
    setIsLoading(true);
    withdrawMethodList()
      .then((data) => {
        if (data.code === "Ok") {
          setWithdrawMethod(data.collectlist);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <main className="fixed top-0 left-0 w-full h-full z-[9999] bg-[rgb(0,0,0,0.6)] flex items-center justify-center px-common-padding-x">
      <section className="bg-second-color max-h-[70vh] h-full w-[660px] rounded-xl py-10 px-2 pb-[1rem] overflow-y-scroll no-scrollbar">
        <section className="flex justify-center">
          <MethodToggle />
        </section>
        {memberData?.wallettype === "usdt" ? (
          <WithdrawStepTwoCrypto chooseCurrency={`USDT`} />
        ) : (
          <>
            {withdrawStep === 1 && (
              <WithdrawStepOne
                setWithdrawData={setWithdrawData}
                setWithdrawStep={setWithdrawStep}
                setChooseCurrency={setChooseCurrency}
                withdrawStep={withdrawStep}
                withdrawMethod={withdrawMethod}
              />
            )}
            {withdrawStep === 2 ? (
              method === "BankCard" ? (
                <WithdrawStepTwoBankCard
                  setWithdrawStep={setWithdrawStep}
                  chooseCurrency={chooseCurrency}
                  withdrawStep={withdrawStep}
                />
              ) : (
                <WithdrawStepTwo
                  withdrawData={withdrawData}
                  setWithdrawStep={setWithdrawStep}
                  chooseCurrency={chooseCurrency}
                  withdrawStep={withdrawStep}
                />
              )
            ) : null}
          </>
        )}
      </section>
    </main>
  );
};

export default Withdraw;
