export const filterCardTag = (Tag) => {
  switch (Tag) {
    case 0:
      return "";
    case 1:
      return "_2";
    case 2:
      return "_3";
    default:
      return "";
  }
};
