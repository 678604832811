import { Button } from "semantic-ui-react";
import { stylesConfig } from "../helpers/stylesConfig";
import SignInUp from "./SignInUp/SignInUp";
import useDeviceType from "../hook/useDeviceType";
import { useNavigate } from "react-router";
import { isLoggedIn } from "../utils/isLoggedIn";
import SignInData from "./SignInUp/SignInData";
import Balance from "./SignInUp/components/Balance";

const Header = () => {
  const deviceType = useDeviceType();

  const navigate = useNavigate();

  return (
    <header
      className={`${
        deviceType === "PC" ? "w-full" : "w-full"
      } sm:h-[80px] h-[60px] fixed top-0 right bg-second-color z-[999] header-shadow flex items-center justify-between`}
    >
      <section className="flex items-center justify-between px-common-padding-x w-full sm:max-w-[1344px] sm:mx-auto h-full">
        <img
          src={stylesConfig.mainLogo}
          className="w-[75px] md:w-[110px] cursor-pointer "
          onClick={() => navigate("/")}
        />
        {isLoggedIn() ? (
          <>
            <Balance />
            <SignInData />
          </>
        ) : (
          <SignInUp Static />
        )}

        {/* <img src={stylesConfig.mainLogo} className="object-cover w-[100px]" /> */}
      </section>
    </header>
  );
};

export default Header;
