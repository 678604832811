import CryptoJS from "crypto-js";

export const cryptData = (password) => {
  const passphrase = "mySecretPassphrase";

  const iv = CryptoJS.lib.WordArray.random(16);

  const key = CryptoJS.PBKDF2(passphrase, iv, {
    keySize: 128 / 32,
    iterations: 1000,
  });

  const ciphertext = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const ivBase64 = iv.toString(CryptoJS.enc.Base64);
  const ciphertextBase64 = ciphertext.toString();
  return ivBase64 + ":" + ciphertextBase64;
};
