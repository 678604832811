import React from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import GetParams from "../../utils/getParams";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../replace_str_constant";

const DepositIframe = () => {
  const depositLink = useSelector((state) => state.isDepositLink);

  const method = GetParams("method");
  return (
    <section className="fixed w-full h-full top-0 left-0 z-[9999999]">
      <CloseDetailPage title={capitalizeFirstLetter(method)} />
      <iframe
        src={depositLink}
        className="h-[calc(100vh-60px)] w-full bg-second-color"
      ></iframe>
    </section>
  );
};

export default DepositIframe;
