import dayjs from "dayjs";

const weekday = require("dayjs/plugin/weekday");

dayjs.extend(weekday);

export const formatTime = (time) => {
  return dayjs(time).format("YYYY-MM-DD");
};

export const getWeek = (weekStart, weekEnd) => {
  const start = dayjs().weekday(weekStart).toDate();
  const end = dayjs().weekday(weekEnd).toDate();
  return { start, end };
};
export const getMonth = (month) => {
  const start = dayjs().subtract(month, "month").startOf("month").toDate();
  const end = dayjs().subtract(month, "month").endOf("month").toDate();
  return { start, end };
};
export const getToday = () => {
  const today = dayjs().toDate();
  return today;
};
export const getTomorrow = () => {
  const tomorrow = dayjs().add(1, "day").toDate();
  return tomorrow;
};
export const getYesterday = () => {
  const yesterday = dayjs().add(-1, "day").toDate();
  return yesterday;
};
export const getSevenDays = () => {
  const sevenDays = dayjs().add(-7, "day").toDate();
  return sevenDays;
};
export const getThirtyDays = () => {
  const thirtyDays = dayjs().add(-30, "day").toDate();
  return thirtyDays;
};
