import { Icon, Input, Select } from "semantic-ui-react";

const InputCustom = ({
  type,
  name,
  onChange,
  focusProps,
  dataProps,
  setDataProps,
  onFocus,
  placeholder,
  options,
  disabled,
  value,
}) => {
  return (
    <Input
      onFocus={onFocus}
      iconPosition={"right"}
      className={`w-full border !mb-[10px] !border-input-border-color rounded border focus:!border-main-input-border`}
    >
      {type === "select" ? (
        <Select
          disabled={disabled}
          className="!bg-input-color w-full custom-select"
          placeholder={placeholder}
          value={value ? value : dataProps?.[name]}
          options={options.map((item, key) => {
            return {
              key: key,
              text: item,
              value: item,
            };
          })}
          onChange={
            onChange
              ? onChange
              : (e, { value }) => setDataProps({ ...dataProps, [name]: value })
          }
        />
      ) : (
        <input
          disabled={disabled}
          type={type}
          value={dataProps?.[name]}
          name={name}
          onChange={onChange}
          className="!bg-input-color !text-[#e2e8e3] font-bold py-[15px] border focus:!border-main-input-border"
          placeholder={placeholder ? placeholder : `Input your ${name}`}
        />
      )}
      {focusProps?.[name] && dataProps?.[name] !== "" ? (
        <Icon
          link
          className={`text-light-text`}
          name={`close`}
          onClick={() => setDataProps({ ...dataProps, [name]: "" })}
        />
      ) : null}
    </Input>
  );
};

export default InputCustom;
