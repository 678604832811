import { useEffect } from "react";
import FilterGameList from "../../utils/FilterGameType";
import GameSelect from "./GameSelect";
import Games from "./Games";
import PlatformSelect from "./PlatformSelect";
import LoaderComponent from "../Loading/LoaderComponent";
import { useLocation } from "react-router";
const GamePage = ({ platformType }) => {
  const games = FilterGameList({ TYPE: platformType });
  const location = useLocation();

  return (
    <main>
      <section className="py-common-padding-x">
        <GameSelect />
      </section>
      <section className="">
        <PlatformSelect
          platformType={platformType}
          defaultPlatform={games?.[0]}
        />
      </section>
      <section className="p-common-padding-x">
        <Games platformType={platformType} defaultPlatform={games?.[0]} />
      </section>
    </main>
  );
};

export default GamePage;
