import React, { useState } from "react";
import { Checkbox, Button, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

import MobilePop from "../../../utils/MobilePop";
import CloseComponent from "../../CloseComponent/CloseComponent";
import { CURRENCY_LIST } from "../../../currency";
import { getMemberInfo, setNowCurrency } from "../../../api/api";
import { loading, loadingDestroy, notice } from "../../../redux/action/action";
import { usePreviousPagePath } from "../../../hook/usePreviousPagePath";
import { commonOpacity } from "../../../animateConstant";
import { storeMemberInfo } from "../../../redux/action/member/memberAction";

const WalletSetting = () => {
  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo);

  const [showCurrencySelect, setShowCurrencySelect] = useState(
    memberData?.basedisplaycur !== "usdt" //如果不是usdt就顯示幣別選擇
  ); //開關顯示幣別選擇

  const [currency, setCurrency] = useState(
    memberData?.basedisplaycur?.toLowerCase() //預設顯示幣別
  ); //設定顯示幣別
  const [buttonLoading, setButtonLoading] = useState(false); //設定按鈕loading

  const previousPage = usePreviousPagePath(); //自製的回到上一頁hook
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(loading());
    setButtonLoading(true);

    setNowCurrency({ basedisplaycurr: currency })
      .then((data) => {
        if (data.code === "Ok") {
          getMemberInfo().then((data) => {
            //選擇完幣別後重新取得會員資料
            if (data.code === "Ok") {
              dispatch(storeMemberInfo(data));
            }
          });
          navigate(previousPage); //成功後回去上一頁
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
        dispatch(loadingDestroy());
      });
  };

  return (
    <MobilePop>
      <main className="flex flex-col h-full justify-between">
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-end`}
          >
            <CloseComponent />
          </div>

          <p className="mb-0 text-3xl font-bold text-main-color text-center mb-[20px]">
            Wallet Settings
          </p>

          <section className="px-[20px]">
            <section className="flex items-center  mb-[30px]">
              <Checkbox className="!mr-[10px] custom-toggle" toggle />
              <div className="font-bold">
                <p className="text-main-color mb-[0px]">隱藏零數金額</p>
                <p className="text-light-text mb-[0px]">
                  您为零数的余额不会显示在钱包中
                </p>
              </div>
            </section>

            <section className="flex items-center  mb-[30px]">
              <Checkbox
                className="!mr-[10px] custom-toggle"
                onChange={(e, { checked }) => {
                  if (!checked) {
                    setCurrency("");
                    return;
                  }
                  setShowCurrencySelect(checked); //這邊設定是否顯示幣別選擇
                }}
                defaultChecked={memberData?.basedisplaycur !== "usdt"}
                toggle
              />
              <div className="font-bold">
                <p className="text-main-color mb-[0px]">
                  以法定货币显示加密货币
                </p>
                <p className="text-light-text mb-[0px]">
                  所有赌注与交易都将以等值加密货币结算
                </p>
              </div>
            </section>

            <section className="grid grid-cols-3">
              {CURRENCY_LIST.filter((item) => item.type !== "crypto").map(
                (option, index) => {
                  return (
                    <div key={index} className="flex items-center">
                      <Radio
                        disabled={!showCurrencySelect}
                        key={index}
                        value={option.text}
                        name="currency"
                        checked={currency === option.text?.toLowerCase()}
                        label={option.text}
                        className="mr-[5px] font-bold custom-radio"
                        onChange={(e, { value }) => {
                          setCurrency(value?.toLowerCase()); //設定幣別
                        }}
                      />
                      <img src={option.icon} className="w-[14px] h-[14px]" />
                    </div>
                  );
                }
              )}
            </section>
          </section>
        </section>
        <Button
          loading={buttonLoading} //按鈕loading
          disabled={buttonLoading} //按鈕loading
          onClick={() => handleSubmit()}
          className="!bg-main-color !text-light-white"
        >
          保存
        </Button>
      </main>
    </MobilePop>
  );
};

export default WalletSetting;
