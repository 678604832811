import { Icon } from "semantic-ui-react";
import { GAME_LIST } from "../helpers/gameList";
import ShowGameDetail from "./ShowGameDetail";
import { useRef, useState } from "react";
import { appName } from "../config";
import SwiperGameList from "./SwiperGameList/SwiperGameList";
import LoaderComponent from "./Loading/LoaderComponent";
import LoadingIcon from "./Loading/LoadingIcon";
import { RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router";

const SlideRight = ({}) => {
  return <section className="trapezoid absolute top-0 right-0 "></section>;
};
const SlideLeft = ({}) => {
  return (
    <section className="trapezoid-reverse absolute top-0 left-0 "></section>
  );
};

const GameCatalog = ({
  GAME_LIST,
  mainTitle,
  gameType,
  showListCount,
  grid,
  hasBottomTitle,
  Autoplay,
  nav,
}) => {
  const swiperRef = useRef();

  const navigate = useNavigate();

  const handleSlideRight = () => {
    const swiper = swiperRef.current.swiper;
    if (swiperRef.current) {
      swiper.slideTo(swiper.activeIndex + 3);
    }
  };
  const handleSlideLeft = () => {
    const swiper = swiperRef.current.swiper;
    if (swiperRef.current) {
      swiper.slideTo(swiper.activeIndex - 3);
    }
  };

  return (
    <section className="bg-gamelist-background relative rounded-[30px] px-[20px] pt-[3px] pb-[20px] mb-[20px]">
      <SlideRight />
      <SlideLeft />
      <section className="relative z-10">
        <section className="flex justify-between h-[53px] mb-[10px]">
          <div>
            <p className="text-main-color sm:text-2xl text-xl font-extrabold italic mb-[0px]">
              {mainTitle}
            </p>
            <p className="text-main-color sm:text-2xl text-xl font-bold italic mb-[0px]">
              {gameType}
            </p>
          </div>
          <div className="flex items-center gap-[5px] mb-[10px]">
            {/* {showListCount && (
              <div className="bg-button-base-color py-[8px] px-[20px] flex items-center rounded-3xl text-light-text">
                ALL{" "}
                <LoadingIcon>
                  <span className="text-main-color font-bold ml-[3px]">
                    {GAME_LIST?.length}
                  </span>
                </LoadingIcon>
              </div>
            )} */}
            <div className="w-[34px] h-[34px] flex items-center justify-center rounded-full bg-button-base-color">
              <Icon
                onClick={handleSlideLeft}
                className="!text-main-color !mb-[5px] !ml-[3px] cursor-pointer"
                name="angle left"
              />
            </div>
            <div className="w-[34px] h-[34px] flex items-center justify-center rounded-full bg-button-base-color">
              <Icon
                onClick={handleSlideRight}
                className="!text-main-color !mb-[5px] !ml-[3px] cursor-pointer"
                name="angle right"
              />
            </div>
          </div>
        </section>
        <LoaderComponent>
          <SwiperGameList
            ref={swiperRef}
            grid={grid}
            GAME_LIST={GAME_LIST}
            autoplay={Autoplay}
            hasBottomTitle={hasBottomTitle}
          />
          {nav && (
            <section
              onClick={() => {
                navigate(nav);
              }}
              className="flex flex-col items-center justify-center text-main-color mt-[10px] cursor-pointer"
            >
              <p className="mb-[1px] text-dark-gray-text">Show more</p>
              <RiArrowDownSLine className="text-3xl arrow-down text-dark-gray-text" />
            </section>
          )}
        </LoaderComponent>
      </section>
    </section>
  );
};

export default GameCatalog;
