import { Dropdown, Icon, Input } from "semantic-ui-react";
import { useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMemberInfo, setNowCurrency, updateWalletType } from "../../../api/api";
import { CURRENCY_LIST } from "../../../currency";
import { loading, loadingDestroy } from "../../../redux/action/action";
import { IoWallet } from "react-icons/io5";
import { useNavigate } from "react-router";
import { formatEightNumber, formatNumber } from "../../../utils/formatNumber";
import { storeMemberInfo } from "../../../redux/action/member/memberAction";
import CurrencySymbol from "../../../utils/CureencySymbol";

const SelectCurrency = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo); // 從redux取得會員資料

  const navigate = useNavigate();
  const symbol = useMemo(() => {
    return memberData?.basedisplaycur;
  }, [memberData?.basedisplaycur]); //會員資料中的貨幣符號

  const handleUpdateWalletType = (type) => {
    dispatch(loading());
    
    updateWalletType({ wallettype: type })
      .then((data) => {
        if (data.code === "Ok") {
          getMemberInfo().then((data) => {
            //選擇完幣別後重新取得會員資料
            if (data.code === "Ok") {
              dispatch(storeMemberInfo(data));
            }
          });
        } else {
        }
      })
      .finally(() => {
        dispatch(loadingDestroy());
      });
    dropdownRef.current?.close(); // 關閉 Dropdown
  };
  const dropdownRef = useRef(null); // 建立 ref 來存取 Dropdown

  

  return (
    <Dropdown className="!absolute !w-[90%]" multiple icon="none" closeOnEscape closeOnBlur ref={dropdownRef}>
      <Dropdown.Menu className="!absolute !left-[-23px] !top-[40px] ">
        <Icon
          className="absolute absolute-center !top-[-7px] !text-3xl text-[#d4d4d4]"
          name="caret up"
        />
        <Input
          onClick={(event) => event.stopPropagation()}
          icon="search"
          iconPosition="right"
          placeholder="Search"
          className="search custom-input input-ui !mt-[10px] !mb-[5px] !w-[160px]"
        />
        <Dropdown.Header />
        <Dropdown.Menu className="!border-t-0" scrolling>
          {CURRENCY_LIST.map((option) => {
            const type = option.type; //貨幣種類(crypto);
            const text = option.text; //貨幣名稱(USDT);
            const value = option.value; //貨幣代號(usdt);
            const icon = option.icon; //貨幣圖片;

            const currencyValue = memberData?.[`${value}money`]; //會員資料中的貨幣數量
            return (
              <Dropdown.Item
                className="!flex items-center justify-between !py-[5px]"
                onClick={(event) => {
                  event.stopPropagation();
                  handleUpdateWalletType(value);
                  handleUpdateWalletType(type); // 以 type 作為參數調用更新函式
                }}
                key={text}
                {...option}
              >
                <p className="mb-0 font-bold max-w-[95px] text-overflow">
                  {CurrencySymbol(symbol)}
                  {
                    type === "crypto"
                      ? formatEightNumber(currencyValue) //虛幣顯示8位數
                      : formatNumber(currencyValue) //實幣顯示2位數
                  }
                </p>
                <div className="flex items-center">
                  <img src={icon} className="w-[14px] h-[14px]" />
                  <span className="w-[30px] ml-[5px]  font-bold">{text}</span>
                </div>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={(event) => {
            event.stopPropagation();
            navigate("walletsettings");
          }}
          className="!flex items-center justify-center !text-grey-text"
        >
          <IoWallet className="mr-[5px] text-2xl" />
          <p className="mb-0">{i18n("walletSet")}</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectCurrency;
