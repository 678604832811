import { AnimateSharedLayout, motion } from "framer-motion";
import { Fragment, useMemo, useState } from "react";

import { HiChevronLeft } from "react-icons/hi";

import { animatePage } from "../../animateConstant";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import { Button } from "semantic-ui-react";
import InputCustom from "../../components/InputComponents/InputCustom";
import { REPLACE_UNDERSCORE_TO_AIR } from "../../replace_str_constant";
import { useTranslation } from "react-i18next";
import { manualWithdraw, withdrawCollect } from "../../api/api";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import SwiperCard from "../Profile/components/Bank/SwiperCard";
import CommonBorder from "../../components/BorderComponent/CommonBorder";
const card = ["", "_2", "_3"];

const WithdrawStepTwoBankCard = ({
  withdrawStep,
  setWithdrawStep,
  chooseCurrency,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [dataFocus, setDataFocus] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openCardSetting, setOpenCardSetting] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleFocus = (e) => {
    setDataFocus({ [e.target.name]: true });
  };

  const handleSubmit = () => {
    manualWithdraw({
      real_name: data.bank_name,
      amounts: data.amounts,
      bank_payWay: data.bank_acc,
      bank_branch: data.bank_acc === "GCash" ? "No branch" : data.bank_code,
      bank_account: data.bank_num,
      type: "Bank Withdraw",
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(notice(data.msg, "success"));
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <AnimateSharedLayout>
      <motion.section
        className="h-full flex flex-col justify-between overflow-y-scroll overflow-x-hidden no-scrollbar"
        layout
        initial={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-between`}
          >
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => setWithdrawStep(1)}
            />
            <CloseComponent />
          </div>
          <p className="text-3xl text-main-color font-extrabold text-center">
            {i18n("withdraw")}
          </p>

          <section>
            <p className="text-center text-light-text my-[20px]">
              {i18n("enterdetail")}
              {chooseCurrency}.
            </p>

            <p className="text-xl font-extrabold text-main-color">
              {i18n("withdraw")}
              {chooseCurrency} {i18n("now")}.
            </p>
            <section>
              <section className="mb-[24px]">
                <SwiperCard
                  noSettings
                  cardNumber={card}
                  setOpenCardSetting={setOpenCardSetting}
                  setData={setData}
                  data={data}
                />
                <CommonBorder />
                <InputCustom
                  placeholder={`Enter your ${chooseCurrency} amounts`}
                  type="text"
                  name="amounts"
                  setDataProps={setData}
                  dataProps={data}
                  onChange={(e) => handleChange(e)}
                  onFocus={(e) => handleFocus(e)}
                  focusProps={dataFocus}
                />
              </section>
            </section>
            <section className="mb-[10px]"></section>
          </section>
        </section>
        <section>
          {data.amounts && (
            <p className="text-light-text">
              {chooseCurrency}{" "}
              <span className="text-main-color font-bold">{data.amounts}</span>{" "}
              In your personal account
            </p>
          )}
          <Button
            loading={buttonLoading}
            disabled={buttonLoading}
            className="!bg-main-color !text-white w-full"
            content={i18n("withdraw")}
            onClick={() => handleSubmit()}
          />
        </section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default WithdrawStepTwoBankCard;
