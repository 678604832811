import { HiOutlinePencilAlt } from "react-icons/hi";
import CardImage from "../../../../images/Member/Bank/card.png";
import { REPLACE_NUMBER_TO_STAR } from "../../../../replace_str_constant";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Card = ({
  setOpenCardSetting,
  cardNumber,
  setCardInformation,
  noSettings,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);

  const bank = memberData?.[`bank_acc${cardNumber}`]; //銀行名稱
  const bankType = memberData?.[`bank_type${cardNumber}`]; // GCash or BankAccount
  const bankNumber = memberData?.[`bank_num${cardNumber}`]; // 銀行帳號
  const bankCode = memberData?.[`bank_code${cardNumber}`]; // 銀行代碼
  const bankDate = memberData?.[`bank_date${cardNumber}`]; // 綁定日期
  const bankName = memberData?.[`bank_name${cardNumber}`]; // 真實名稱

  return (
    <section
      className="h-[220px] w-[340px] mx-auto bg-no-repeat bg-[100%,100%] rounded-[10px]  flex flex-col justify-between text-white"
      style={{ backgroundImage: `url(${CardImage})` }}
    >
      <div className="">
        <div className="flex items-center justify-between font-semibold mb-[15px] px-[10px] pt-3">
          <p className="mb-0 flex items-center">
            {bank}
            {noSettings || (
              <HiOutlinePencilAlt
                onClick={() => {
                  setOpenCardSetting(true);
                  setCardInformation({
                    [`tag`]: cardNumber,
                    [`bank_name${cardNumber}`]: bankName,
                    [`bank_acc${cardNumber}`]: bank,
                    [`bank_code${cardNumber}`]: bankCode,
                    [`bank_num${cardNumber}`]: bankNumber,
                  });
                }}
                className="ml-[7px] text-3xl text-main-color cursor-pointer"
              />
            )}
          </p>
          <p className="mb-0">Type:{bankType}</p>
        </div>
        <p className="text-xl font-semibold ml-[70px]">
          {i18n("card.cardNumber")}
        </p>
        <p className="text-center text-3xl font-bold">
          {REPLACE_NUMBER_TO_STAR(bankNumber)}
          {bankType === "GCash" ? null : (
            <span className="text-lg font-semibold">-{bankCode}</span>
          )}
        </p>
      </div>
      <div className="flex items-center justify-between px-[10px] pb-3">
        <p className="font-semibold">{bankName}</p>
        <p className="font-semibold">
          {i18n("card.bindDate")}
          <br />
          {bankDate}
        </p>
      </div>
    </section>
  );
};

export default Card;
