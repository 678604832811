import { TiArrowSortedUp } from "react-icons/ti";
import { HiLogout } from "react-icons/hi";

import useDeviceType from "../../hook/useDeviceType";
import { MENU_LIST } from "./helper";
import { logoutUser } from "../../api/api";
import UserLogout from "../../api/UserLogout";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import { cleanMemberInfo } from "../../redux/action/member/memberAction";
import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const iconStyle = "text-2xl mr-[10px]";

const SlideMenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const deviceType = useDeviceType();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogoutUser = () => {
    UserLogout();
    logoutUser().then((data) => {
      if (data.code === "Ok") {
        dispatch(notice("Logout Success", "success", 7));
        dispatch(cleanMemberInfo());
        setTimeout(() => {
          window.location.reload();
        }, 700);
      }
    });
  };

  return (
    <section
      ref={ref}
      className="w-[175px] sm:w-[200px] absolute right-0 top-[44px] bg-second-color rounded-[8px] footer-shadow "
    >
      {deviceType === "PC" && (
        <TiArrowSortedUp className="absolute text-second-text text-4xl top-[-18px] right-0 text-white" />
      )}
      {MENU_LIST.map((item, index) => {
        return (
          <div
            className={`flex text-sm sm:text-normal items-center text-main-color font-semibold hover:bg-coin-bg cursor-pointer transition duration-300 py-[9px] px-[12px] sm:p-[16px] ${
              item.border && "border-t border-game-background"
            }`}
            onClick={() => navigate(item.link)}
            key={index}
          >
            {item.icon}
            <p className="mb-0 text-black">{i18n(item.text?.toLowerCase())}</p>
          </div>
        );
      })}
      <div
        onClick={() => handleLogoutUser()}
        className={`flex text-sm sm:text-normal items-center text-main-color font-semibold hover:bg-coin-bg cursor-pointer transition duration-300 py-[9px] px-[12px] sm:p-[16px] `}
      >
        <HiLogout className="text-2xl mr-[10px]" />
        <p className="mb-0 text-black">{i18n("logout")}</p>
      </div>
    </section>
  );
});

export default SlideMenu;
