const CurrencySymbol = (currency) => {
  const filterCurrency = (currency) => {
    switch (currency) {
      case "usdt":
        return "$";
      case "btc":
        return "฿";
      case "eth":
        return "Ξ";
      case "php":
        return "₱";
      case "vnd":
        return "₫";
      default:
        return "$";
    }
  };
  return filterCurrency(currency);
};

export default CurrencySymbol;
