import { useMemo } from "react";
import { useSelector } from "react-redux";
/** 
過濾遊戲分類
**/
export default function FilterGameList({ TYPE }) {
  const GameListArray = useSelector((state) => state.isGameList);
  const suppliers = useMemo(
    () => Object.entries(GameListArray),
    [GameListArray]
  );
  const gameList = suppliers.filter((s) => {
    let result = s[1].hasOwnProperty(TYPE);
    if (result) {
      return s.map((s) => s[0]);
    }
  });
  const LIST = gameList.map((s) => s[0]);

  return LIST;
}
