import { Label } from "semantic-ui-react";
import { CURRENCY_SYMBOL } from "../../../constant";
import { capitalizeFirstLetter } from "../../../replace_str_constant";
import { useState } from "react";

const Record = ({ single, amounts, time, method, status, lastBalance }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const statusFilter = (status) => {
    switch (status) {
      case 99:
        return { text: "Cancelled", color: "red" };
        break;
      case 0:
        return { text: "Confirming", color: "orange" };
        break;
      case 1:
        return { text: "Processing", color: "yellow" };
        break;
      case 2:
        return { text: "Finish", color: "green" };
        break;
      default:
        return { text: "Error", color: "red" };
    }
  };

  return (
    <>
      <section
        className={`${
          single && "bg-input-color"
        } p-common-padding-x flex justify-between`}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <div className="flex flex-col justify-between">
          <p className="text-dark-gray-text text-lg font-semibold mb-[7px]">
            {method}
          </p>
          <p className="text-dark-gray-text text-sm mb-0">{time}</p>
        </div>

        <div className="flex flex-col justify-between items-end">
          <p className="text-dark-gray-text text-lg font-semibold mb-[7px]">
            {CURRENCY_SYMBOL} {amounts}
          </p>
          <p className="text-main-color text-sm mb-0">
            <Label color={statusFilter(status)?.color}>
              {statusFilter(status)?.text}
            </Label>
          </p>
        </div>
      </section>
      {openDetail && (
        <section
          className={`${
            single && "bg-game-background"
          } p-common-padding-x text-dark-gray-text`}
        >
          <p className="text-sm">
            Action: <span className="font-bold text-lg">{method}</span>
          </p>
          <p className="text-sm">
            Transaction time: <span className="font-bold text-lg">{time}</span>
          </p>
          <p className="text-sm">
            Amounts: <span className="font-bold text-lg">{amounts}</span>
          </p>
          <p className="text-sm">
            Last balance:{" "}
            <span className="font-bold text-lg">{lastBalance}</span>
          </p>
        </section>
      )}
    </>
  );
};

export default Record;
