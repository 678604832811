import { AnimateSharedLayout, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { IoCloseOutline } from "react-icons/io5";
import { HiChevronLeft } from "react-icons/hi";
import { Button, Input } from "semantic-ui-react";

import { animatePage } from "../../animateConstant";
import InputComponents from "../../components/InputComponents/InputComponents";
import { CURRENCY_SYMBOL, DEPOSIT_AMOUNT } from "../../constant";
import InputCustom from "../../components/InputComponents/InputCustom";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import GetParams from "../../utils/getParams";
import ErrorHint from "../../components/Hint/ErrorHint";
import { depositFund } from "../../api/api";
import { loading, loadingDestroy, notice } from "../../redux/action/action";
import { storeDepositLink } from "../../redux/action/cashflow/cashflowAction";

const DepositSteoTwo = ({ chooseCurrency, setDepositStep, depositStep }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);

  const [data, setData] = useState({});
  const [dataFocus, setDataFocus] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const method = GetParams("method");
  const min = Number(GetParams("min"));
  const max = Number(GetParams("max"));

  const amountCondition = useMemo(() => {
    const amounts = Number(data.amounts);
    if (amounts) {
      if (amounts < min || amounts > max) {
        return true;
      } else {
        return false;
      }
    }
  }, [data.amounts]);

  const handleSubmit = () => {
    dispatch(loading());

    depositFund({
      payway3: method,
      amount: data.amounts,
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(storeDepositLink(data.url));
          navigate("depositfund" + location.search);
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        dispatch(loadingDestroy());
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleFocus = (e) => {
    setDataFocus({ [e.target.name]: true });
  };

  return (
    <AnimateSharedLayout>
      <motion.section
        className="h-auto flex flex-col justify-between overflow-y-scroll no-scrollbar"
        layout
        initial={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-between`}
          >
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => setDepositStep(1)}
            />
            <CloseComponent />
          </div>
          <p className="text-3xl text-main-color font-extrabold text-center">
            {i18n("deposit")}
          </p>

          <section>
            <p className="text-center text-light-text my-[20px]">
              {i18n("enterdetail")}
              {chooseCurrency}.
            </p>

            <p className="text-xl font-extrabold text-main-color">
              {i18n("deposit")} {chooseCurrency} {i18n("now")}.
            </p>
            <section>
              <section className="mb-[24px]">
                <InputCustom
                  placeholder={`Enter your ${chooseCurrency} type`}
                  type="text"
                  name="currency"
                  setDataProps={setData}
                  dataProps={data}
                  onChange={(e) => handleChange(e)}
                  onFocus={(e) => handleFocus(e)}
                  focusProps={dataFocus}
                />
              </section>
              <section className="grid items-center grid-cols-3 gap-[8px] mb-[20px]">
                {DEPOSIT_AMOUNT.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      className="!bg-second-color !border !border-solid border-light-text !text-dark-gray-text text hover:!text-dark-gray-text !px-[3px]"
                      content={`${chooseCurrency} ${item.amount}`}
                      onClick={() => setData({ ...data, amounts: item.amount })}
                    />
                  );
                })}
              </section>
              <section>
                <InputCustom
                  placeholder={`Enter your ${chooseCurrency} amount`}
                  type="number"
                  name="amounts"
                  setDataProps={setData}
                  dataProps={data}
                  onChange={(e) => handleChange(e)}
                  onFocus={(e) => handleFocus(e)}
                  focusProps={dataFocus}
                />
              </section>
            </section>
            <section className="mb-[10px]"></section>
          </section>
        </section>
        <section>
          {amountCondition && (
            <ErrorHint>
              The withdrawal amount should fall within the range of{" "}
              {CURRENCY_SYMBOL}
              {min} to {CURRENCY_SYMBOL}
              {max}.
            </ErrorHint>
          )}
          {data.amounts && (
            <p className="text-light-text">
              {chooseCurrency}{" "}
              <span className="text-main-color font-bold">{data.amounts}</span>{" "}
              In your personal account
            </p>
          )}
          <Button
            onClick={handleSubmit}
            disabled={amountCondition}
            className="!bg-main-color !text-white w-full"
            content={i18n("deposit")}
          />
        </section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default DepositSteoTwo;
