const numeral = require("numeral");

export const formatNumber = (NUMBER) => {
  if (typeof NUMBER === "string") {
    NUMBER = Number(NUMBER);
  }
  return numeral(NUMBER).format("0,0.00");
};

export const formatEightNumber = (NUMBER) => {
  return numeral(NUMBER).format("0,0.00000000");
};

export const filterNumberColor = (NUMBER) => {
  if (typeof NUMBER === "string") {
    NUMBER = Number(NUMBER);
  }
  if (NUMBER > 0) {
    return "text-green-500";
  } else if (NUMBER === 0) {
    return "text-light-text";
  } else {
    return "text-red-500";
  }
};

export const filterSign = (NUMBER) => {
  if (typeof NUMBER === "string") {
    NUMBER = Number(NUMBER);
  }
  if (NUMBER > 0) {
    return "+";
  } else if (NUMBER === 0) {
    return "";
  } else {
    return "-";
  }
};
