import { useNavigate } from "react-router";
import { capitalizeFirstLetter } from "../../../replace_str_constant";

const SelectMethod = ({
  method,
  currency,
  setChooseCurrency,
  setDepositStep,
  min,
  max,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="px-[24px] py-[10px] bg-dark-gray-text rounded-xl flex items-center justify-between mb-[10px] cursor-pointer"
      onClick={() => {
        setChooseCurrency(currency);
        navigate(`?method=${method}&min=${min}&max=${max}`);
        setDepositStep(2);
      }}
    >
      <p className="text-light-white font-bold">
        {capitalizeFirstLetter(method)}
        &nbsp;
        {`(${currency})`}
      </p>
    </div>
  );
};

export default SelectMethod;
