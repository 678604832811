import { motion, AnimateSharedLayout } from "framer-motion";

import { useSelector } from "react-redux";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { animatePageNotOpacity } from "../../animateConstant";
import { getFixedStyle } from "../../hook/useDeviceType";
import GetParams from "../../utils/getParams";
import { Base64 } from "js-base64";
import CommonBorder from "../../components/BorderComponent/CommonBorder";

const InboxDetail = () => {
  const detailState = useSelector((state) => state.isDetailPage);

  const subject = GetParams("subject");
  const message = Base64.decode(GetParams("message"));
  const date = GetParams("date");
  const marContent = Base64.decode(GetParams("marContent"));

  return (
    <AnimateSharedLayout>
      <main className={` ${getFixedStyle()} bg-second-color z-[10000]`}>
        <CloseDetailPage title={subject} />
        <motion.section
          layout
          initial={detailState ? "hiddenRight" : "hiddenLeft"}
          animate="visible"
          exit={detailState ? "hiddenRight" : "hiddenLeft"}
          variants={animatePageNotOpacity}
          className="p-common-padding-x"
        >
          <p className="text-light-white text-xl font-semibold mb-[5px]">
            {subject}
          </p>
          <p className="text-light-white">{date}</p>
          <CommonBorder />
          <p className="break-words text-light-white">{message}</p>
          {marContent && <p className="text-red-600">*{marContent}</p>}
        </motion.section>
      </main>
    </AnimateSharedLayout>
  );
};

export default InboxDetail;
