import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Grid } from "swiper";
import { useNavigate } from "react-router";

import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/swiper-bundle.css";

import { GAME_LIST } from "../../helpers/gameList";
import { appName } from "../../config";
import { REPLACE_AIR, REPLACE_SPACE } from "../../replace_str_constant";
import useDeviceType from "../../hook/useDeviceType";
import i18next from "i18next";
import GAMEITEM from "../GAMEITEM/GAMEITEM";

const SwiperGameList = React.forwardRef((props, ref) => {
  const { grid, GAME_LIST, hasBottomTitle, autoplay } = props;

  return (
    <Swiper
      ref={ref}
      className="mySwiper h-full w-full"
      loop={grid ? false : true}
      grid={{
        rows: grid ? grid : 1,
        spaceBetween: 10,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      onSwiper={(swiper) => swiper}
      modules={[Grid, ...(autoplay ? [Autoplay] : [])]}
      effect="fade"
      spaceBetween={10}
      slidesPerView={3}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        1024: {
          slidesPerView: 7,
          spaceBetween: 20,
        },
      }}
    >
      {GAME_LIST?.map((item, index) => {
        const en_Name = item?.ename;
        const zh_Name = item?.cname;
        const platform = item?.platform;
        const gameCode = item?.gameCode;
        const gameType = item?.gameType;

        return (
          <SwiperSlide key={index}>
            <GAMEITEM
              gameProps={{ en_Name, zh_Name, platform, gameCode, gameType }}
              hasBottomTitle={hasBottomTitle}
              index={index}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
});

export default SwiperGameList;
