import Promotion1 from "../../images/Promotion/Promotion1.png";

export const PROMOTION_LIST = [
  { background: Promotion1 },
  { background: Promotion1 },
  { background: Promotion1 },
];

export const PROMOTION_BANNER_BACKGROUND = [
  "linear-gradient(45deg, rgb(93, 234, 121) 80%, rgb(82, 255, 203)) 20% center",
  "linear-gradient(45deg, rgb(147, 126, 82) 80%, rgb(35, 35, 35)) 20% center",
  "linear-gradient(45deg, rgb(111, 191, 254) 80%, rgb(18, 83, 187)) 20% center",
];
