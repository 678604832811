import React, { useEffect } from "react";
import useDeviceType from "../hook/useDeviceType";
import PopWindow from "./PopWindow";

const OverflowHidden = ({ children, closePop }) => {
  const deviceType = useDeviceType();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [children]);
  return (
    <section>
      {deviceType === "PC" && !closePop ? (
        <PopWindow>{children}</PopWindow>
      ) : (
        children
      )}
    </section>
  );
};

export default OverflowHidden;
