import React, { useEffect, useState } from "react";
import { GAME_LIST } from "./helper";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loading, loadingDestroy } from "../../redux/action/action";

const GameSelect = () => {
  const [fixed, setFixed] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 200) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={`${fixed && "h-[77px]"}`}>
      <section
        className={`grid grid-cols-6 md:w-[500px] items-center ${
          fixed &&
          "fixed w-full top-[60px] bg-second-color z-[999] pb-common-padding-x"
        }`}
      >
        {GAME_LIST.map((game, index) => {
          return (
            <div
              key={index}
              className="flex justify-center cursor-pointer"
              onClick={() => {
                dispatch(loading());
                setTimeout(() => {
                  navigate(game.link);
                  dispatch(loadingDestroy());
                }, 200);
              }}
            >
              <img className="w-[50px]" src={game?.icon} />
            </div>
          );
        })}
      </section>
    </section>
  );
};

export default GameSelect;
