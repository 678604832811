import usdt from "./images/Currency/tether.png";
import peso from "./images/Currency/peso.png";
import dong from "./images/Currency/dong.png";

export const CURRENCY_LIST = [
  { text: "USDT", icon: usdt, value: "usdt", type: "crypto" },
  { text: "PHP", icon: peso, value: "php", type: "normal" },
  // { text: "VND", icon: dong, value: "vnd", type: "normal" },
];

export const FILTER_CURRENCY_IMAGE = (type) => {
  switch (type) {
    case "usdt":
      return usdt;
    case "php":
      return peso;
    case "vnd":
      return dong;
    default:
      return;
  }
};

export const CRYPTO_LIST = [
  { text: "USDT", value: "usdt", type: "crypto", key: "usdt" },
];
export const CHAIN_LIST = [{ text: "TRC-20", value: "TRC-20", key: "TRC-20" }];
