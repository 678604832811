import React, { Fragment, useMemo } from "react";

import Carousel from "../../components/Carousel";
import EventListSelect from "../../components/EventListSelect/EventListSelect";
import GameCatalog from "../../components/GameCatalog";
import { appName } from "../../config";
import { useSelector } from "react-redux";
import FilterGameList from "../../utils/FilterGameType";
import Search from "../../components/Search/Search";
import GameSelect from "../../components/GameSelect/GameSelect";
import { GAME_LIST } from "../../components/GameSelect/helper";
import useDeviceType from "../../hook/useDeviceType";

const Home = () => {
  const GAMELIST = useSelector((state) => state.isGameList);

  const deviceType = useDeviceType();

  const CQ9_GAME = useMemo(() => {
    return GAMELIST?.["CQ9"]?.["SLOT"];
  }, [GAMELIST]);

  return (
    <Fragment>
      <section className="px-common-padding-x py-common-padding-y">
        <Carousel />
      </section>
      <section className="pb-common-padding-y">
        <GameSelect />
      </section>
      <section>
        <GameCatalog
          GAME_LIST={CQ9_GAME?.slice(0, 20)}
          showListCount
          mainTitle={`${appName}`}
          gameType={`Original`}
          Autoplay
        />
        <section className="px-common-padding-x">
          <EventListSelect />
        </section>
        {GAME_LIST.map((game, index) => {
          const filterByType = (obj) => {
            let result = [];
            for (let key in obj) {
              if (key === game?.text) {
                result = result.concat(obj[key]);
              } else if (typeof obj[key] === "object") {
                result = result.concat(filterByType(obj[key]));
              }
            }
            return result;
          };
          const gameList = filterByType(GAMELIST).slice(0, 9);

          return (
            <GameCatalog
              GAME_LIST={gameList}
              mainTitle={game?.title}
              gameType={game?.gameType}
              hasBottomTitle
              grid={deviceType === "PC" ? 1 : gameList?.length < 9 ? 1 : 3}
              nav={game?.link}
            />
          );
        })}
        {/* <GameCatalog
          GAME_LIST={CQ9_GAME?.slice(0, 60)}
          showListCount
          grid={3}
          hasBottomTitle
          mainTitle={`Recommand`}
        /> */}
      </section>
    </Fragment>
  );
};

export default Home;
