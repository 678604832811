import { useEffect } from "react";
import { useNavigate } from "react-router";
import { isLoggedIn } from "./isLoggedIn";

const Authenticator = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin", { replace: true });
    }
  }, []);

  return <>{children}</>;
};

export default Authenticator;
