import { AnimateSharedLayout, motion } from "framer-motion";

import { IoCloseOutline } from "react-icons/io5";

import { animatePage } from "../../animateConstant";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import SelectMethod from "./components/SelectMethod";
import { useTranslation } from "react-i18next";

const WithdrawStepOne = ({
  withdrawStep,
  withdrawMethod,
  setChooseCurrency,
  setWithdrawStep,
  setWithdrawData,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);

  return (
    <AnimateSharedLayout>
      <motion.section
        layout
        initial={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={withdrawStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <div
          className={`flex items-center text-light-text text-4xl mb-[20px] justify-end`}
        >
          <CloseComponent />
        </div>
        <p className="text-3xl text-main-color font-extrabold text-center">
          {i18n("withdraw")}
        </p>

        <section>
          <p className="text-center text-light-text my-[20px]">
            {i18n("methodText")}
          </p>
          <section className="mb-[24px]">
            <p className="font-extrabold text-xl text-main-color mb-[10px]">
              {i18n("recommend")}
            </p>
            {withdrawMethod.map((item, index) => {
              const method = item.payway3; //提款方式

              return (
                <SelectMethod
                  setChooseCurrency={setChooseCurrency}
                  setWithdrawStep={setWithdrawStep}
                  setWithdrawData={setWithdrawData}
                  currency={`PHP`}
                  method={method}
                  data={item}
                />
              );
            })}
          </section>
        </section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default WithdrawStepOne;
