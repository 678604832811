import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { GoThreeBars } from "react-icons/go";
import { FaPiggyBank, FaHistory } from "react-icons/fa";
import { HiLogout } from "react-icons/hi";

import { CloseMenu, openMenu } from "../../redux/action/action";
import useDeviceType from "../../hook/useDeviceType";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";

const iconStyle = "text-2xl mr-[10px]";

const GameBox = () => {
  const dispatch = useDispatch();
  const gameLink = useSelector((state) => state.isGameLink);

  const deviceType = useDeviceType();

  const prevPage = usePreviousPagePath();
  const navigate = useNavigate();

  const menu = [
    {
      icon: <GoThreeBars className={iconStyle} />,
      text: "Menu",
      func: () => {
        dispatch(openMenu());
      },
    },
    {
      icon: <FaPiggyBank className={iconStyle} />,
      text: "Deposit",
      func: () => {
        navigate("deposit");
      },
    },
    {
      icon: <FaHistory className={iconStyle} />,
      text: "Bet Record",
      func: () => {
        navigate("betRecord");
      },
    },
    {
      icon: <HiLogout className={iconStyle} />,
      text: "Leave",
      func: () => {
        dispatch(CloseMenu());
        navigate(prevPage);
      },
    },
  ];
  return (
    <main className="flex flex-col overflow-x-hidden overflow-y-auto w-full min-h-full lg:static fixed top-0 left-0 z-[1000]">
      <section
        className="relative w-full flex flex-col h-full"
        id="game-iframe"
      >
        <iframe
          src={gameLink}
          className="lg:h-[calc(100vh-120px)] h-[calc(100vh-40px)] w-full bg-second-color"
        ></iframe>
        {deviceType === "Mobile" && (
          <div className="h-[40px] fixed bottom-0 left-0 w-full grid grid-cols-4 text-light-white gamebox-shadow bg-game-background">
            {menu.map((item, index) => {
              return (
                <div
                  onClick={item.func}
                  key={index}
                  className="flex items-center justify-center cursor-pointer hover:text-main-color transition duration-300"
                >
                  {item.icon}
                </div>
              );
            })}
          </div>
        )}
      </section>
    </main>
  );
};

export default GameBox;
