import { AnimatePresence, motion } from "framer-motion";

import CloseComponent from "../../components/CloseComponent/CloseComponent";
import { appName } from "../../config";
import { popUpVariant } from "../../animateConstant";
import { useState } from "react";
import { getFixedStyle } from "../../hook/useDeviceType";

import AddToHome_en from "../../images/App/AddToHome_en.png";
import AddToHome_zh from "../../images/App/AddToHome_zh.png";
import PWAhint from "../../images/App/PWAhint.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const DownloadApp = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  const [isClosing, setIsClosing] = useState(false);

  return (
    <AnimatePresence>
      {!isClosing && (
        <motion.main
          variants={popUpVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            duration: 0.2,
          }}
          className={`${getFixedStyle()} bg-[rgba(0, 0, 0, 0.5)] backdrop-blur-md flex items-center justify-center px-[10px]`}
        >
          <section className="bg-main-color pb-[25px] pt-[10px] px-[15px] rounded-xl text-light-white max-w-[450px] w-full">
            <section className="flex items-center mb-[10px] justify-end text-3xl">
              <CloseComponent delay setProps={setIsClosing} />
            </section>
            <p className="text-lg font-semibold">
              {i18n("Quickly access")} {appName} {i18n("on")} iPhone
            </p>
            <img src={PWAhint} className="w-full object-cover mb-[20px]" />
            <p>
              {i18n("You can add an icon to the")} iPhone{" "}
              {i18n("home screen to access")} {appName}.
            </p>
            <p>{i18n("Hint")}</p>
            {i18next.language === "en" ? (
              <img src={AddToHome_en} className="w-full object-cover" />
            ) : (
              <img src={AddToHome_zh} className="w-full object-cover" />
            )}
          </section>
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default DownloadApp;
