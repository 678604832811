import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useState } from "react";
import { Select } from "semantic-ui-react";
import { getSevenDays, getThirtyDays, getToday } from "../../utils/getDay";

const dateList = [
  { key: "today", value: [getToday(), getToday()], text: "Today" },
  { key: "7 days", value: [getSevenDays(), getToday()], text: "7 days" },
  { key: "30 days", value: [getThirtyDays(), getToday()], text: "30 days" },
];

const TimePicker = ({ setDatePicker, datePicker }) => {
  const onChange = (event, data) => setDatePicker(data.value);

  return (
    <div className="flex justify-between gap-[10px]">
      <SemanticDatepicker
        value={datePicker}
        className="!bg-third-color rounded-xl overflow-hidden custom-picker"
        onChange={onChange}
        clearIcon
        clearable={false}
        type="range"
      />
      <Select
        placeholder={dateList[0].text}
        onChange={(e, { value }) => setDatePicker(value)}
        className="!bg-second-color !border !border-solid !rounded-xl !min-w-[140px]"
        options={dateList}
      />
    </div>
  );
};

export default TimePicker;
