import { Button, Icon, Input } from "semantic-ui-react";

const InputComponents = ({
  inputProps,
  index,
  focusProps,
  dataProps,
  setDataProps,
  onFocus,
  placeholder,
}) => {
  return (
    <section
      className={`${
        inputProps?.needButton && "flex items-center gap-[10px] justify-between"
      }  mb-[15px]`}
    >
      <Input
        onFocus={onFocus}
        key={index}
        iconPosition={"right"}
        className={`${
          inputProps?.needButton ? "w-full" : "w-full"
        } border !border-input-border-color rounded`}
      >
        <input
          disabled={inputProps?.disabled}
          type={inputProps?.type}
          value={dataProps?.[inputProps.name]}
          name={inputProps?.name}
          onChange={inputProps?.onChange}
          className="!bg-input-color !text-[#e2e8e3] font-bold py-[15px] !border-input-border-color border focus:!border-main-input-border"
          placeholder={
            placeholder ? placeholder : `Input your ${inputProps.label}`
          }
        />
        {focusProps[inputProps.name] && dataProps[inputProps.name] !== "" ? (
          <Icon
            link
            className={`text-dark-gray-text ${inputProps.needIcon && "!mr-[30px]"}`}
            name={`close`}
            onClick={() =>
              setDataProps({ ...dataProps, [inputProps.name]: "" })
            }
          />
        ) : null}

        {inputProps.needIcon && (
          <Icon
            link
            onClick={inputProps.function}
            className="text-dark-gray-text"
            name={inputProps.icon1}
          />
        )}
      </Input>
      {inputProps.needButton && (
        <Button
          disabled={inputProps.buttonProps?.disabled}
          onClick={inputProps.buttonProps?.onClick}
          className="!py-[10px] w-full !bg-gray-button-bg !text-white"
        >
          {inputProps.buttonText}
        </Button>
      )}
    </section>
  );
};

export default InputComponents;
