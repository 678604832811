import { AnimateSharedLayout, motion } from "framer-motion";
import { useMemo, useState } from "react";
import QRCode from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { IoCloseOutline } from "react-icons/io5";
import { HiChevronLeft } from "react-icons/hi";
import { Button, Input, Select } from "semantic-ui-react";

import { animatePage } from "../../animateConstant";
import InputComponents from "../../components/InputComponents/InputComponents";
import { CURRENCY_SYMBOL, DEPOSIT_AMOUNT } from "../../constant";
import InputCustom from "../../components/InputComponents/InputCustom";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import GetParams from "../../utils/getParams";
import ErrorHint from "../../components/Hint/ErrorHint";
import { depositFund } from "../../api/api";
import { loading, loadingDestroy, notice } from "../../redux/action/action";
import { storeDepositLink } from "../../redux/action/cashflow/cashflowAction";
import InputCopy from "../../components/CopyComponent/InputCopy";
import { useTranslation } from "react-i18next";

const DepositStepTwoCrypto = ({
  chooseCurrency,
  setDepositStep,
  depositStep,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);

  const [data, setData] = useState({});
  const [dataFocus, setDataFocus] = useState({});

  const memberData = useSelector((state) => state.isMemberInfo);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const method = GetParams("method");
  const min = Number(GetParams("min"));
  const max = Number(GetParams("max"));

  const amountCondition = useMemo(() => {
    const amounts = Number(data.amounts);
    if (amounts) {
      if (amounts < min || amounts > max) {
        return true;
      } else {
        return false;
      }
    }
  }, [data.amounts]);


  const currencyOptions = [
    { value: 'USDT', label: 'USDT' },
    // 其他貨幣選項
  ];
  
  const networkOptions = [
    { value: 'ETH', label: 'ETH' },
    { value: 'BSC', label: 'BSC' },
    // 其他網絡選項
  ];
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('');

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
  };

  const handleNetworkChange = (value) => {
    setSelectedNetwork(value);
  };

  const handleSubmit = () => {
    dispatch(loading());

    depositFund({
      payway3: method,
      amount: data.amounts,
    })
      .then((data) => {
        if (data.code === "Ok") {
          dispatch(storeDepositLink(data.url));
          navigate("depositfund" + location.search);
        } else {
          dispatch(notice(data.msg, "error"));
        }
      })
      .finally(() => {
        dispatch(loadingDestroy());
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleFocus = (e) => {
    setDataFocus({ [e.target.name]: true });
  };

  return (
    <AnimateSharedLayout>
      <motion.section
        className="h-full flex flex-col justify-between overflow-y-scroll no-scrollbar p-1"
        layout
        initial={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        animate="visible"
        exit={depositStep === 1 ? "hiddenRight" : "hiddenLeft"}
        variants={animatePage}
      >
        <section>
          <div
            className={`flex items-center text-light-text text-4xl mb-[20px] justify-between`}
          >
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => setDepositStep(1)}
            />
            <CloseComponent />
          </div>
          <p className="text-3xl text-main-color font-extrabold text-center">
            {i18n("deposit")}
          </p>

          <section>
            
            <div className="flex justify-center items-center my-2">
              <div className="m-2 w-[50%]">
                <p className="mb-1 font-semibold">貨幣</p>
              <Select
                  placeholder={`請選擇貨幣`}
                  className="ui input !border-border-light-color border focus:!border-main-input-border rounded-[5px] border w-full p-4 !min-w-[5em] w-auto"
                  value={selectedCurrency}
                  onChange={(e, { value }) => handleCurrencyChange(value)}
                  options={currencyOptions.map((option) => 
                    {return{key:option.value,value:option.value,text:option.label}})}
                >
                </Select>
              </div>

              <div className="m-2 w-[50%]">
                <p className="mb-1 font-semibold">網絡</p>
                <Select
                  placeholder={`請選擇網絡`}
                  className="ui input !border-border-light-color border focus:!border-main-input-border rounded-[5px] border w-full p-4 !min-w-[5em] w-auto"
                  value={selectedNetwork}
                  onChange={(e, { value }) => handleNetworkChange(value)}
                  options={networkOptions.map((option) => 
                    {return{key:option.value,value:option.value,text:option.label}})}
                >
                </Select>
              </div>
            </div>

            <p className="text-center text-light-text my-[20px]">
                    {i18n("enterdetail")}
                    {chooseCurrency}.
            </p>
            <p className="text-xl font-extrabold text-main-color">
              {i18n("deposit")}
              {chooseCurrency} {i18n("now")}.
            </p>
            <section>
              <p className="">您的USDT存款地址</p>
              <section className="mb-[24px]">
                <InputCopy copyLink={memberData?.cryptoAddress} />
                <ErrorHint>{i18n("cryptoHint")}</ErrorHint>
              </section>
              <section className="flex justify-center">
                <QRCode value={memberData?.cryptoAddress} renderAs="canvas" />
              </section>
              <p className="text-center text-light-text my-[20px]">
                {i18n("cryptoNotice")}
              </p>
            </section>
            <section className="mb-[10px]"></section>
          </section>
        </section>
        <section></section>
      </motion.section>
    </AnimateSharedLayout>
  );
};

export default DepositStepTwoCrypto;
