export const languageMapping = (language) => {
  switch (language) {
    case "zh_chs":
      return "简体";
    case "en":
      return "English";
  }
};

export const LANGUAGES = [
  { text: "English", language: "en", value: "en" },
  { text: "简体", language: "zh_chs", value: "zh_chs" },
];
